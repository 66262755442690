export default function Dots() {
    return (
        <div>
            <svg height="55" width="80" class="loader">
                <line strokeWidth="1.5px" stroke="#fff" x1="0" y1="45" x2="10" y2="45" class="dot"/>
                <line strokeWidth="1.5px" stroke="#fff" x1="0" y1="50" x2="10" y2="50" class="dot"/>
                <line strokeWidth="1.5px" stroke="#fff" x1="0" y1="35" x2="10" y2="35" class="dot"/>
            </svg>
        </div>
    )
}