import Masonry from 'react-masonry-css';
import PortfolioListItem from './PortfolioListItem';
import './PortfolioList.css';
import { useSelector } from 'react-redux';

const PortfolioList = () => {
    const breakpointColumnsObj = {
        default: 2,
        1024: 1
      };

      const portfolioItems = useSelector((state) => state.portfolioItems.value)


    const getView = () => {
        if (portfolioItems.length > 0) {
            return <section className="py-2">
            <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid pt-0 pb-32 lg:py-32 px-16 lg:px-48"
                columnClassName="my-masonry-grid_column">
                <div></div>
                <PortfolioListItem key={0} index={0} portfolioItem={portfolioItems[0]}/>
                {portfolioItems.map((portfolioItem, i) => {
                        if(i > 0)
                            return <PortfolioListItem key={i} index={i} portfolioItem={portfolioItem} />
                        else
                            return null
                    })}
            </Masonry>
        </section>
        } else {
            return <div></div>
        }
    }

    return (getView())
}
export default PortfolioList;