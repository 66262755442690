import { createSlice } from '@reduxjs/toolkit'
import sanityClient from '../../sanityClient'

export const carouselSlice = createSlice({
  name: 'carousel',
  initialState: {
    value: []
  },
  reducers: {
    loadImages: (state, action) => {
      state.value = action.payload
    },
  }
})

export const fetchCarouselImages = (dispatch) => {
  sanityClient.fetch(`*[_type == "carousel"]{
    title,
    description,
    image{
        asset->{
            _id,
            url
        },
        alt
    }
}`)
    .then((data) => dispatch(loadImages(data)))
    .catch((err) => console.error(err));
}

// Action creators are generated for each case reducer function
export const { loadImages } = carouselSlice.actions

export default carouselSlice.reducer

