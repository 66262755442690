import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { init } from '@emailjs/browser';
import emailjs from '@emailjs/browser'
import ReCAPTCHA from "react-google-recaptcha";
import { motion, AnimatePresence } from "framer-motion"
import MowerSVG from '../../images/mower'

init("user_ebGd3uREMi6nnb8NlwCi5");

const ContactForm = (props) => {
    const { t } = useTranslation();
    const [message, setMessage] = useState('')
    const [author, setAuthor] = useState('')
    const [email, setEmail] = useState('')
    const [sent, setSent] = useState(false)
    const [result, setResult] = useState(0)
    const [error, setError] = useState('')
    const [sending, setSending] = useState(false)

    const recaptchaRef = React.createRef();

    const btnVariants = {
        hidden: {
            y: "-10vw"
        },
        visible: {
            y: "0vw",
            transition: { delay: 0, duration: 1 },
        },
        exit: {
            y: "10vw",
            transition: { ease: "easeInOut" }
        }
    }


    const handleClickSend = () => {
        setSent(true)
        if (checkForm()) {
            sendEmail()
        }
    }
    const sendEmail = async () => {
        const token = await recaptchaRef.current.executeAsync();
        if (author !== '' && email !== '' && message !== '') {
            setSending(true)
            var templateParams = {
                from_name: author,
                message: message,
                reply_to: email,
                "g-recaptcha-response": token
            };

            await emailjs.send('service_n7yuy8i', 'template_n37mz9x', templateParams).then((res) => {
                setResult(1)
            }).catch((err) => {
                setResult(2)
                setError('Erreur du serveur')
            })
            setSending(false)
        }
    }

    const checkForm = () => {
        if (author === '' || email === '' || message === '') {
            setError('Veuillez compléter les champs manquants')
            return false
        } else if (!(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email))) {
            setError("L'adresse email n'est pas valide.")
            return false
        }
        setError("")
        return true
    }

    const getInputAuthor = () => {
        var borderColor = 'border-greenEllie-dark';
        if (author === '' && sent) {
            borderColor = 'border-error'
        }
        return <input name='firstname' value={author} onChange={e => setAuthor(e.target.value)} className={"inline appearance-none bg-transparent border-b-2 w-6/12 focus:outline-none " + borderColor} type="text" />
    }

    const getInputEmail = () => {
        var borderColor = 'border-greenEllie-dark';
        if (email === '' && sent) {
            borderColor = 'border-error'
        }
        return <input name='email' value={email} onChange={e => setEmail(e.target.value)} className={"inline appearance-none bg-transparent border-b-2 w-6/12 focus:outline-none " + borderColor} type="email" />
    }

    const getInputMessage = () => {
        var borderColor = 'border-greenEllie-dark';
        if (message === '' && sent) {
            borderColor = 'border-error'
        }
        return <div className='relative'>
            <textarea onChange={e => setMessage(e.target.value)} class={"resize-none border w-full bg-greyEllie text-greenEllie-dark p-10 focus:outline-none " + borderColor}></textarea>
            {getErrorServer()}
        </div>
    }

    const getErrorMsg = () => {
        if (error !== '') {
            return <div className='flex flex-row justify-end items-center font-sans'><div className='rounded-full w-5 h-5 bg-error flex justify-center items-center mr-4'><span className='text-white text-xs'>!</span></div><p className='text-right text-error'>{error}</p></div>
        } else {
            return null
        }
    }

    const getErrorServer = () => {
        if (result === 2) {
            return <div className='bg-error p-4 absolute bottom-0 text-white text-sm w-full font-sans justify-center flex'>
                <div>
                    <p>Oups, une erreur est survenue lors de l’envoi. Veuillez réessayer plus tard ou contacter l’administrateur du site internet.</p>
                    <a className='underline' href='mailto:info@elie-paysage.ch'>Envoyer un mail via ma boite mail</a>
                </div>
            </div>
        }
    }

    const getBtnSend = () => {
        if (sending) {
            return <button disabled type='button' className="w-72 h-14 bg-greenEllie-dark text-whiteEllie border border-greenEllie-dark font-bold py-2 px-12 flex space-x-10 items-center justify-between">
                <span className='whitespace-nowrap'>Envoi en cours</span>
                <MowerSVG width={40} height={20} />
            </button>
        }
        if (result === 1) {
            return <button disabled type='button' className="w-72 h-14 bg-greenEllie-dark text-whiteEllie border border-greenEllie-dark font-bold py-2 px-12 flex space-x-10 items-center justify-between">
                <span>Message envoyé</span>
                <motion.div
                    variants={btnVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit">
                    <svg className="h-4 w-4" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M33 1L11 23L1 13" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </motion.div>
            </button>
        }
        return <button type='button' onClick={handleClickSend} className="w-72 h-14 bg-greenEllie-dark hover:bg-whiteEllie text-whiteEllie hover:text-greenEllie-dark border border-greenEllie-dark font-bold py-2 px-12 flex space-x-10 items-center justify-between">
            <span>{t("send")}</span>
            <svg className="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 23L23 1" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M1 1H23V23" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </button>
    }

    return (
        <section className="flex px-10 lg:px-48 bg-whiteEllie lg:h-screen">
            <form className="w-full flex flex-col justify-between">
                <div className="font-display font-bold text-3xl md:text-5xl text-greenEllie-dark py-10 flex flex-row flex-wrap space-y-4">
                    <p className="inline w-full">{t("hello")}</p>
                    <p className='inline mr-4'>{t("myNameIs")} </p>
                    {getInputAuthor()}
                    <p className="inline mr-4">{t("myEmailIs")}:</p>
                    {getInputEmail()}
                </div>
                {getInputMessage()}
                {getErrorMsg()}
                <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey="6LcfuvcdAAAAAN1qd-1ua_YbsUubcp29m0BNvRtc"
                />
                <div className="flex flex-col-reverse md:flex-row justify-between text-greenEllie-dark pt-10 md:pt-20 pb-10 space-y-4">
                    <div>Elie Paysage SA <br />
                        Chemin du Robin 17 <br />
                        1544 Gletterens</div>
                    <div>info@elie-paysage.ch<br />
                        +41 79 612 95 69
                    </div>
                    <div>
                        {getBtnSend()}
                    </div>
                </div>
            </form>
        </section>

    );
}
export default ContactForm;