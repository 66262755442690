export default function ArrowMore() {
    return (
        <svg width="120" height="120" className='absolute bottom-0 right-0'>
            <path class="st0" d="M84,60.2c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0s0,0,0,0c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.1-0.1-0.2L60.4,36.1
            c-0.2-0.2-0.6-0.2-0.8,0c-0.2,0.2-0.2,0.6,0,0.8l22.6,22.6H36.5c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h45.7L59.6,83.1
            c-0.2,0.2-0.2,0.6,0,0.8c0.1,0.1,0.2,0.2,0.4,0.2s0.3-0.1,0.4-0.2l23.5-23.5C84,60.3,84,60.3,84,60.2z"/>
            <g>
            </g>
        </svg>

    )
}