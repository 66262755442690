import React from 'react';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { updateCurrentIndex } from '../../redux/reducers/portfolioSlice';
import store from '../../redux/store';
import animationData from '../../lotties/scroll_dot_icon.json';
import Lottie from 'react-lottie';


const PortfolioItem = (props) => {

    const { i18n } = useTranslation();
    const navigate = useNavigate();
    const portfolioItems = useSelector((state) => state.portfolioItems.value)
    const currentIndex = useSelector((state) => state.portfolioItems.currentIndex)
    const portfolioItem = portfolioItems[currentIndex]

    const nextPortfolioItem = () => {
        let newIndex = 0;
        if (currentIndex === (portfolioItems.length - 1)) {
            store.dispatch(updateCurrentIndex(0))
            newIndex = 0;
        } else {
            store.dispatch(updateCurrentIndex(currentIndex + 1))
            newIndex = currentIndex + 1;
        }
        navigate("/portfolio/" + portfolioItems[newIndex].title[i18n.language]);
        window.scrollTo(0, 0)
    }

    const prevPortfolioItem = () => {
        let newIndex = 0;
        if (currentIndex === 0) {
            store.dispatch(updateCurrentIndex(portfolioItems.length - 1))
            newIndex = portfolioItems.length - 1;
        } else {
            store.dispatch(updateCurrentIndex(currentIndex - 1))
            newIndex = currentIndex - 1;
        }
        navigate("/portfolio/" + portfolioItems[newIndex].title[i18n.language]);
        window.scrollTo(0, 0)
    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid"
        }
    };

    const getView = () => {
        if (portfolioItem !== undefined) {
            return <section className="bg-greenEllie-dark flex flex-col">
                <div className="flex flex-col px-10 lg:px-48">
                    <div className='h-screen w-full lg:pt-20'>
                        <img className="object-cover w-full h-5/6" src={portfolioItem.mainImage.asset.url} alt="Display" />
                        <h1 className="text-whiteEllie font-bold font-display text-3xl md:text-4xl lg:text-5xl -mt-6 lg:-mt-8">{portfolioItem.title[i18n.language]}</h1>
                        <div className="text-center py-10 hidden lg:block">
                            <Lottie
                                options={defaultOptions}
                                height={30}
                                width={30}
                                isStopped={false}
                                isPaused={false}
                            />
                        </div>
                    </div>
                    <h2 className="text-whiteEllie font-bold uppercase pt-10 lg:pt-0">{portfolioItem.subtitle[i18n.language]}</h2>
                    <p className="text-whiteEllie w-9/12 pt-4">{portfolioItem.description[i18n.language]}</p>
                    <div className="flex flex-col lg:flex-row pt-12 lg:pt-24 ">
                        <div class="grid grid-cols-2 gap-y-14 gap-x-2 lg:gap-x-24 w-full lg:w-9/12 lg:pr-28">
                            {portfolioItem.images.map((image, i) => {
                                if (i < 2)
                                    return <div key={i} className="w-full">
                                        <img src={image.asset.url}
                                            alt={image.asset._id} />
                                    </div>
                                else
                                    return <div className="w-full col-span-2 hidden lg:block">
                                        <img key={i} src={image.asset.url}
                                            alt={image.asset._id} />
                                    </div>
                            })}
                        </div>
                        <p className="text-whiteEllie py-10 lg:py-0 w-full lg:w-3/12">{portfolioItem.content[i18n.language]}</p>
                        <div class="grid grid-cols-2 gap-y-14 w-full lg:hidden">
                            {portfolioItem.images.map((image, i) => {
                                if (i >= 2)
                                    return <div className="w-full col-span-2">
                                        <img key={i} src={image.asset.url}
                                            alt={image.asset._id} />
                                    </div>
                                else
                                    return null;
                            })}
                        </div>
                    </div>
                    <div className="flex flex-row justify-between py-20">
                        <button onClick={prevPortfolioItem} className="rounded-full h-20 w-20 md:h-24 md:w-24 p-3 flex items-center justify-center border-2 border-whiteEllie text-whiteEllie  opacity-20 hover:opacity-100">
                            <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M87 44L1 44" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M44 87L1 44L44 1" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                        <button onClick={nextPortfolioItem} className="rounded-full h-20 w-20 md:h-24 md:w-24 p-3 flex items-center justify-center border-2 border-whiteEllie text-whiteEllie  opacity-20 hover:opacity-100">
                            <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 44H87" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M44 1L87 44L44 87" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                    </div>
                </div>
            </section>
        } else {
            return <div></div>
        }
    }

    return (getView())
}
export default PortfolioItem;