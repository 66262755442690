import React from 'react';
import Footer from '../components/Footer';
import NavigationList from '../components/navigation/NavigationList';
import ServiceGallery from '../components/services/ServiceGallery';
import {motion} from 'framer-motion'

const Services = ({variants}) => {
  return (
    <motion.div className="text-xs md:text-sm lg:text-base"
      variants={variants}
      initial="hidden"
      animate="visible"
      exit="exit">
      <ServiceGallery />
      <div className="bg-whiteEllie pb-20"></div>
      <NavigationList textStyle="text-greenEllie-dark border-greenEllie-dark" bgStyle="bg-whiteEllie" />
      <Footer />
    </motion.div>
  );
}
export default Services;