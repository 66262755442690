import React from 'react';
import { Link } from 'react-router-dom';
const NavigationListItemMenu = (props) => {
    return (
        <Link to={props.to}>
            <li className={"flex justify-between items-center pr-1 uppercase text-3xl md:text-4xl lg:text-6xl font-display font-bold py-4 border-b-2 hover:opacity-20 " + props.textStyle}>
                {props.name}
            </li>
        </Link>
    );
}
export default NavigationListItemMenu;