import React from 'react';
import TeamList from '../components/about/TeamList';
import Footer from '../components/Footer';
import NavigationList from '../components/navigation/NavigationList';
import {motion} from 'framer-motion'
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';

const Newsletter = ({ variants }) => {
    const { t } = useTranslation();

  return (
    <motion.div className="flex-grow text-xs md:text-sm lg:text-base"
      variants={variants}
      initial="hidden"
      animate="visible"
      exit="exit">
      <div className="bg-greenEllie-dark h-screen w-full">
          <h1 className="text-whiteEllie text-5xl">{t("newsletterConfirmationTitle")}</h1>
          <p className="text-whiteEllie text-2xl">{t("newsletterConfirmationText")}</p>
          <Link to="/">
            {t('goBackHome')}
          </Link>
      </div>
    </motion.div>
  );
}
export default Newsletter;