import React from 'react';
import { useTranslation } from "react-i18next";

const TeamMember = (props) => {
    const { i18n } = useTranslation();

    return (
        <div className="flex flex-col items-center text-whiteEllie">

            {props.teamMember.image ?
                <div className="rounded-full h-28 w-28 md:h-36 md:w-36 lg:h-40 lg:w-40 mb-5">
                    <img className="object-contain" src={props.teamMember.image.asset.url} alt="TM" />
                </div>
                :
                <div className="rounded-full h-28 w-28 md:h-36 md:w-36 lg:h-40 lg:w-40 bg-gray-400 flex items-center justify-center mb-5"> {props.teamMember.firstName[0]}{props.teamMember.lastName[0]} </div>}
            <div className="text-center items-center font-bold mb-2">{props.teamMember.firstName} {props.teamMember.lastName}</div>
            <div className="text-center items-center">{props.teamMember.position[i18n.language]}</div>
        </div>
    );
}
export default TeamMember;