import React from 'react';
import Footer from '../components/Footer';
import NavigationList from '../components/navigation/NavigationList';
import PortfolioItem from '../components/portfolio/PortfolioItem';
import { motion } from 'framer-motion'

const PortfolioItemPage = ({ variants }) => {
  return (
    <motion.div className="text-xs md:text-sm lg:text-base"
      variants={variants}
      initial="hidden"
      animate="visible"
      exit="exit">
      <PortfolioItem />
      <NavigationList textStyle="text-whiteEllie border-whiteEllie" bgStyle="bg-greenEllie-dark" />
      <Footer />
    </motion.div>
  );
}
export default PortfolioItemPage;