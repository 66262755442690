import './App.css';
import { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import Navbar from './components/navbar/Navbar';
import { Provider } from 'react-redux';
import store from './redux/store';
import { fetchCarouselImages } from './redux/reducers/carouselSlice'
import Loading from './pages/Loading';
import { useSelector } from 'react-redux'
import './i18n.js';
import { fetchServices } from './redux/reducers/servicesSlice';
import { fetchPortfolioItems } from './redux/reducers/portfolioSlice';
import { fetchTeamMembers } from './redux/reducers/teamSlice';
import { CookiesProvider } from "react-cookie";
import RoutesNav from './components/RoutesNav'

function App() {
  const [ready, setReady] = useState(false);

  useEffect(async () => {
    await store.dispatch(fetchCarouselImages)
    await store.dispatch(fetchServices)
    await store.dispatch(fetchPortfolioItems)
    await store.dispatch(fetchTeamMembers)
    setTimeout(() => {
      setReady(true)
    }, 2000)

  }, [])

  if (ready) {
    return (
      <CookiesProvider>
        <Provider store={store}>
          <Router>
            <RoutesNav />
          </Router>
        </Provider>
      </CookiesProvider>
    );
  } else {
    return <Loading />
  }
}

export default App;
