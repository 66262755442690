import React from 'react';
import Footer from '../components/Footer';
import NavigationList from '../components/navigation/NavigationList';
import PortfolioList from '../components/portfolio/PortfolioList';
import PortfolioListHeader from '../components/portfolio/PortfolioListHeader';
import {motion} from 'framer-motion'

const Portfolio = ({ variants }) => {
  return (
    <motion.div className="flex-grow text-xs md:text-sm lg:text-base bg-greenEllie-dark"
      variants={variants}
      initial="hidden"
      animate="visible"
      exit="exit">
      <PortfolioListHeader />
      <PortfolioList />
      <NavigationList textStyle="text-whiteEllie border-whiteEllie" bgStyle="bg-greenEllie-dark" />
      <Footer />
    </motion.div>
  );
}
export default Portfolio;