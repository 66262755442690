import React from 'react';
import { useTranslation } from "react-i18next";

const PortfolioListHeader = (props) => {
    const { t, i18n } = useTranslation();

    return (
        <div className="flex justify-center px-16 lg:px-48 pt-10 lg:pt-32 lg:pl-48 lg:pr-0 lg:w-1/2">
            <div className="text-whiteEllie font-bold font-display text-3xl md:text-4xl lg:text-5xl">{t("discoverPortfolio")}</div>
        </div>
    );
}
export default PortfolioListHeader;