
export default function SeeMoreFr() {
    return (
        <svg width="120" height="120" className='absolute bottom-0 right-0 more-text'>
            <g>
                <polygon class="st0" points="73.6,7.6 69.9,6.9 70.4,4.5 73.5,5.2 73.7,4.2 70.6,3.5 71,1.4 74.8,2.2 75,1.2 70.2,0.2 68.6,7.7 
		73.4,8.7 	"/>
                <path class="st0" d="M75.8,9.4l1-2.8l0,0c0-0.4,0.2-0.6,0.3-0.9c0.1-0.2,0.3-0.3,0.5-0.4s0.4-0.1,0.5-0.2c0.2,0,0.4,0,0.6,0.1
		c0.3,0.1,0.5,0.2,0.6,0.4c0.1,0.2,0.2,0.4,0.3,0.6c0,0.2,0,0.5,0,0.7s-0.1,0.5-0.2,0.7l-0.9,2.7l1.1,0.4l1-3
		c0.1-0.2,0.1-0.4,0.2-0.6c0-0.2,0.1-0.5,0.1-0.7s-0.1-0.5-0.1-0.8c-0.1-0.3-0.2-0.5-0.5-0.7c-0.2-0.2-0.5-0.4-0.9-0.5
		c-0.5-0.2-0.9-0.2-1.4-0.1c-0.3,0.1-0.5,0.2-0.7,0.3l0.2-0.7l-0.9-0.3L74.7,9L75.8,9.4z"/>
                <path class="st0" d="M84.9,13.3c0.6,0.3,1.2,0.5,1.7,0.4s0.9-0.4,1.2-0.9c0.1-0.3,0.2-0.5,0.2-0.7s-0.1-0.5-0.3-0.8
		c-0.2-0.3-0.5-0.6-0.8-0.9c-0.3-0.3-0.6-0.6-0.8-0.7c-0.2-0.2-0.3-0.3-0.3-0.4c0-0.1,0-0.2,0-0.3c0.1-0.2,0.3-0.3,0.5-0.3
		s0.5,0.1,0.8,0.2c0.3,0.2,0.5,0.4,0.6,0.6c0.1,0.2,0.1,0.5,0,0.8l1.1,0.4c0.1-0.3,0.1-0.6,0.1-1c0.1-0.4-0.1-0.7-0.3-1
		c-0.3-0.3-0.6-0.5-1-0.7s-0.8-0.3-1.1-0.4c-0.4,0-0.7,0-1,0.2c-0.3,0.1-0.5,0.4-0.7,0.7c-0.1,0.2-0.2,0.5-0.2,0.7s0.1,0.5,0.3,0.7
		c0.2,0.3,0.5,0.6,0.8,0.9c0.3,0.3,0.6,0.6,0.7,0.7c0.2,0.2,0.2,0.3,0.3,0.4c0,0.1,0,0.2-0.1,0.4s-0.3,0.3-0.5,0.4
		c-0.2,0-0.5,0-0.8-0.2s-0.6-0.4-0.7-0.7s-0.2-0.6-0.1-0.9l-1.1-0.4c-0.2,0.5-0.1,1.1,0.1,1.5C83.8,12.6,84.3,13,84.9,13.3z"/>
                <path class="st0" d="M91.1,17l0.8,0.6l2-2.9c0.1-0.2,0.3-0.4,0.4-0.6c0.1-0.2,0.2-0.4,0.2-0.6c0.1-0.4,0-0.8-0.2-1.2s-0.5-0.7-1-1
		c-0.5-0.4-1.1-0.6-1.6-0.5c-0.5,0-0.9,0.2-1.3,0.5l0.7,0.8c0.3-0.2,0.5-0.3,0.8-0.3s0.6,0.1,0.8,0.3c0.4,0.3,0.6,0.6,0.7,0.8
		c0,0.3-0.1,0.5-0.3,0.8c-0.2-0.1-0.4-0.2-0.6-0.3c-0.3-0.2-0.6-0.3-0.9-0.4c-0.3-0.1-0.6-0.2-0.8-0.3c-0.3-0.1-0.6-0.1-0.8-0.1
		c-0.3,0-0.5,0.1-0.7,0.2c-0.2,0.1-0.4,0.3-0.6,0.5c-0.2,0.3-0.3,0.5-0.3,0.8s0,0.6,0.2,0.9c0.1,0.3,0.4,0.6,0.7,0.8
		c0.4,0.3,0.9,0.5,1.3,0.5c0.3,0,0.7-0.1,1-0.2L91.1,17z M90.8,15.7c-0.3,0-0.5-0.1-0.8-0.3c-0.2-0.1-0.3-0.3-0.4-0.4
		c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0.1-0.3,0.1-0.4c0.1-0.1,0.2-0.2,0.3-0.3c0.1,0,0.2-0.1,0.4-0.1c0.1,0,0.3,0,0.4,0.1
		c0.2,0.1,0.4,0.1,0.6,0.2c0.2,0.1,0.5,0.2,0.7,0.3s0.4,0.2,0.5,0.3c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.2-0.3,0.3-0.4,0.4
		c-0.1,0.1-0.3,0.2-0.5,0.3C91.2,15.7,91,15.7,90.8,15.7z"/>
                <polygon class="st0" points="94.9,20.1 100.3,17.1 99.4,16.4 95.4,18.7 97.1,14.4 96.2,13.7 94.1,19.4 	" />
                <path class="st0" d="M98.1,21.2c-0.2,0.5-0.2,1-0.1,1.4c0.1,0.5,0.3,0.9,0.7,1.4c0.4,0.4,0.8,0.7,1.3,0.8c0.5,0.1,1,0.1,1.5,0
		s1-0.4,1.4-0.8s0.7-0.8,0.9-1.3c0.2-0.5,0.2-1,0.1-1.4c-0.1-0.5-0.3-0.9-0.7-1.4c-0.4-0.4-0.8-0.7-1.3-0.8c-0.5-0.1-1-0.1-1.4,0
		c-0.5,0.1-1,0.4-1.4,0.8C98.5,20.2,98.2,20.7,98.1,21.2z M99,22c0.1-0.4,0.3-0.9,0.8-1.3c0.3-0.3,0.6-0.5,0.9-0.6s0.6-0.1,0.9-0.1
		c0.3,0.1,0.6,0.2,0.8,0.5c0.4,0.4,0.5,0.8,0.4,1.3c-0.1,0.4-0.3,0.9-0.8,1.3c-0.4,0.4-0.9,0.6-1.3,0.7c-0.5,0.1-0.9-0.1-1.2-0.5
		C99.1,22.9,98.9,22.4,99,22z"/>
                <rect x="101.3" y="25.2" transform="matrix(0.7902 -0.6128 0.6128 0.7902 6.078 69.2329)" class="st0" width="5.7" height="1.1" />
                <rect x="107" y="22.5" transform="matrix(0.7905 -0.6125 0.6125 0.7905 8.4085 70.7086)" class="st0" width="1.1" height="1.1" />
                <path class="st0" d="M108,26.1l-4.8,3.2l0.6,0.9l2.4-1.6c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.1,0.4-0.1,0.6-0.1s0.4,0,0.6,0.1
		s0.4,0.2,0.5,0.4c0.1,0.2,0.2,0.4,0.3,0.5l0.9-0.6c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1-0.1-0.2-0.3-0.4-0.4c-0.1-0.1-0.3-0.2-0.5-0.3
		c-0.2-0.1-0.3-0.1-0.5-0.1c-0.1,0-0.2,0-0.3,0l0.8-0.5L108,26.1z"/>
                <path class="st0" d="M104.9,36.9l2.7-1.3c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.5,0,0.9,0.2,1.4s0.6,0.8,1,1.1c0.4,0.2,0.9,0.3,1.4,0.3
		s1-0.1,1.5-0.4c0.5-0.2,0.9-0.6,1.3-1c0.3-0.4,0.5-0.8,0.6-1.3s0-0.9-0.2-1.4s-0.6-0.8-0.9-1.1c-0.1-0.1-0.3-0.1-0.4-0.2l0.5-0.2
		l-0.4-0.9l-7.5,3.6L104.9,36.9z M112.2,35c0.2,0.3,0.2,0.6,0.1,0.9c-0.1,0.3-0.2,0.6-0.4,0.8c-0.2,0.2-0.5,0.4-0.8,0.6
		c-0.3,0.2-0.7,0.3-1,0.3s-0.6,0-0.9-0.2c-0.3-0.1-0.5-0.4-0.6-0.7c-0.2-0.3-0.2-0.6-0.2-0.9c0.1-0.3,0.2-0.5,0.4-0.8
		c0.2-0.2,0.5-0.4,0.9-0.6c0.4-0.2,0.7-0.3,1-0.3s0.6,0,0.9,0.1C111.9,34.4,112.1,34.7,112.2,35z"/>
                <rect x="109.4" y="39.5" transform="matrix(0.9372 -0.3489 0.3489 0.9372 -6.8568 42.045)" class="st0" width="7.8" height="1.1" />
                <path class="st0" d="M114.1,46.6c-0.3,0.1-0.6,0.1-0.9,0.1c-0.2,0-0.5-0.1-0.6-0.2c-0.2-0.1-0.3-0.2-0.4-0.4s-0.2-0.3-0.2-0.5
		c-0.1-0.3-0.1-0.5,0-0.8c0.1-0.2,0.2-0.4,0.4-0.6c0.2-0.2,0.4-0.3,0.6-0.4c0.2-0.1,0.4-0.2,0.6-0.2l2.7-0.7l-0.3-1.1l-3.1,0.8
		c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.4,0.2-0.6,0.4c-0.2,0.1-0.4,0.3-0.6,0.6c-0.2,0.2-0.3,0.5-0.3,0.8c-0.1,0.3,0,0.6,0.1,1
		c0.1,0.5,0.4,0.9,0.7,1.2c0.2,0.2,0.4,0.3,0.7,0.4l-0.7,0.2l0.3,1l5.5-1.5l-0.3-1.1L114.1,46.6z"/>
                <path class="st0" d="M116.2,49.2c-0.3,0-0.5,0.1-0.7,0.3s-0.3,0.4-0.4,0.7s-0.2,0.7-0.2,1.2c-0.1,0.5-0.1,0.8-0.2,1
		c0,0.2-0.1,0.4-0.2,0.5s-0.2,0.1-0.3,0.2c-0.2,0-0.5,0-0.6-0.2c-0.2-0.2-0.3-0.4-0.3-0.8c-0.1-0.4,0-0.7,0.1-1s0.3-0.5,0.6-0.6
		l-0.3-1.1c-0.5,0.2-0.9,0.5-1.2,1c-0.2,0.5-0.3,1.1-0.2,1.8c0.1,0.7,0.4,1.3,0.7,1.6c0.4,0.4,0.9,0.5,1.4,0.4
		c0.3,0,0.5-0.1,0.7-0.3s0.3-0.4,0.4-0.7s0.2-0.7,0.2-1.2s0.1-0.8,0.1-1.1c0-0.2,0.1-0.4,0.2-0.5s0.2-0.1,0.3-0.2
		c0.2,0,0.4,0,0.6,0.2s0.3,0.5,0.3,0.8s0,0.6-0.1,0.9c-0.1,0.2-0.3,0.4-0.6,0.5l0.4,1.1c0.3-0.1,0.6-0.3,0.8-0.5
		c0.2-0.2,0.4-0.6,0.5-0.9c0.1-0.4,0.1-0.8,0-1.2s-0.2-0.8-0.4-1.1c-0.2-0.3-0.4-0.5-0.7-0.7C116.8,49.2,116.5,49.1,116.2,49.2z"/>
                <polygon class="st0" points="112.5,67.2 111.6,72 112.6,72.2 113.3,68.5 115.6,68.9 115.1,72 116.1,72.2 116.7,69.1 118.8,69.5 
		118.1,73.2 119.1,73.4 120,68.6 	"/>
                <path class="st0" d="M110.9,74.4l2.9,0.9l0,0c0.3,0.1,0.6,0.2,0.8,0.4c0.2,0.1,0.4,0.3,0.5,0.5s0.2,0.4,0.2,0.5
		c0,0.2,0,0.4-0.1,0.6c-0.1,0.3-0.2,0.5-0.4,0.7c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0-0.5,0-0.7,0s-0.5-0.1-0.7-0.2l-2.7-0.8l-0.3,1.1
		l3,1c0.2,0.1,0.4,0.1,0.6,0.1s0.5,0.1,0.7,0c0.3,0,0.5-0.1,0.8-0.2c0.2-0.1,0.5-0.3,0.7-0.5s0.4-0.5,0.5-0.9
		c0.2-0.5,0.2-0.9,0.1-1.4c-0.1-0.3-0.2-0.5-0.3-0.7l0.7,0.2l0.3-0.9l-5.5-1.7L110.9,74.4z"/>
                <path class="st0" d="M112.1,83.4c-0.3-0.1-0.5-0.2-0.7-0.2s-0.5,0.1-0.7,0.3c-0.3,0.2-0.6,0.5-0.9,0.9c-0.3,0.3-0.6,0.6-0.7,0.8
		c-0.2,0.2-0.3,0.2-0.4,0.3c-0.1,0-0.2,0-0.4-0.1c-0.2-0.1-0.4-0.3-0.4-0.5s0-0.5,0.2-0.8s0.4-0.6,0.7-0.7c0.3-0.2,0.6-0.2,0.9-0.1
		l0.4-1.1c-0.5-0.2-1.1-0.1-1.5,0.1c-0.5,0.3-0.9,0.7-1.2,1.3c-0.3,0.6-0.4,1.2-0.3,1.7s0.4,0.9,0.9,1.2c0.3,0.1,0.5,0.2,0.8,0.2
		c0.2,0,0.5-0.1,0.8-0.3c0.3-0.2,0.6-0.5,0.9-0.8c0.3-0.4,0.5-0.6,0.7-0.8c0.2-0.2,0.3-0.3,0.4-0.3s0.2,0,0.3,0
		c0.2,0.1,0.3,0.3,0.3,0.5s-0.1,0.5-0.2,0.8c-0.2,0.3-0.4,0.5-0.6,0.6s-0.5,0.1-0.8,0.1l-0.3,1.1c0.3,0.1,0.6,0.1,1,0.1
		c0.3-0.1,0.6-0.2,0.9-0.5c0.3-0.2,0.5-0.6,0.7-1s0.3-0.8,0.3-1.1c0-0.4,0-0.7-0.2-1C112.6,83.8,112.4,83.6,112.1,83.4z"/>
                <path class="st0" d="M108.4,89.7c0.2,0.3,0.3,0.5,0.3,0.8s-0.1,0.6-0.3,0.8c-0.3,0.4-0.5,0.6-0.8,0.7c-0.2,0-0.5-0.1-0.8-0.2
		c0.1-0.2,0.2-0.4,0.3-0.6c0.2-0.3,0.3-0.6,0.4-0.9s0.2-0.6,0.3-0.8c0.1-0.3,0.1-0.6,0.1-0.8c0-0.3-0.1-0.5-0.2-0.7
		s-0.3-0.4-0.6-0.5c-0.3-0.2-0.5-0.3-0.8-0.3s-0.6,0-0.9,0.2s-0.6,0.4-0.8,0.8c-0.3,0.4-0.5,0.9-0.5,1.3c0,0.3,0.1,0.7,0.2,1
		l-0.7-0.5l-0.5,0.8l3,1.9c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.4,0.2,0.6,0.2c0.4,0.1,0.8,0,1.2-0.2c0.4-0.2,0.7-0.5,1-1
		c0.4-0.6,0.5-1.1,0.5-1.6s-0.2-0.9-0.5-1.3L108.4,89.7z M106.2,90.8c-0.1,0.2-0.2,0.4-0.2,0.5c-0.1-0.1-0.2-0.1-0.3-0.2
		c-0.2-0.1-0.3-0.3-0.4-0.4s-0.2-0.3-0.3-0.5c-0.1-0.2-0.1-0.4-0.1-0.7c0-0.3,0.1-0.5,0.3-0.8c0.1-0.2,0.3-0.3,0.4-0.4
		s0.3-0.1,0.4-0.1s0.3,0.1,0.4,0.1c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.2,0.1,0.4c0,0.1,0,0.3-0.1,0.4c-0.1,0.2-0.1,0.4-0.2,0.6
		C106.5,90.2,106.3,90.5,106.2,90.8z"/>
                <polygon class="st0" points="100.6,93.8 103.7,99 104.4,98.1 102,94.2 106.4,95.8 107.1,94.9 101.3,92.9 	" />
                <path class="st0" d="M100.9,97.8c-0.4-0.4-0.9-0.7-1.3-0.9c-0.5-0.2-1-0.2-1.4-0.1c-0.5,0.1-0.9,0.4-1.3,0.8s-0.7,0.8-0.8,1.3
		c-0.1,0.5-0.1,1,0,1.5c0.2,0.5,0.4,0.9,0.9,1.4c0.4,0.4,0.9,0.7,1.3,0.9c0.5,0.2,1,0.2,1.4,0.1c0.5-0.1,0.9-0.4,1.3-0.8
		s0.7-0.8,0.8-1.3c0.1-0.5,0.1-1,0-1.4C101.6,98.7,101.4,98.2,100.9,97.8z M100.8,100.4c-0.1,0.3-0.2,0.6-0.5,0.8
		c-0.4,0.4-0.8,0.5-1.3,0.5c-0.4-0.1-0.9-0.3-1.3-0.7c-0.4-0.4-0.6-0.9-0.7-1.3c-0.1-0.5,0.1-0.9,0.5-1.3s0.8-0.5,1.3-0.5
		c0.5,0.1,0.9,0.3,1.3,0.7c0.3,0.3,0.5,0.6,0.6,0.9S100.8,100.1,100.8,100.4z"/>

                <rect x="97.4" y="105.9" transform="matrix(0.7775 -0.6289 0.6289 0.7775 -45.1437 85.2749)" class="st0" width="1.1" height="1.1" />

                <rect x="94.6" y="100.2" transform="matrix(0.7771 -0.6294 0.6294 0.7771 -43.6577 82.8562)" class="st0" width="1.1" height="5.7" />
                <path class="st0" d="M90.6,102.9l1.7,2.4c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.1,0.4,0.1,0.6s0,0.4-0.1,0.6s-0.2,0.4-0.4,0.5
		c-0.2,0.1-0.4,0.3-0.5,0.3l0.6,0.9c0.2-0.1,0.3-0.2,0.5-0.3c0.1-0.1,0.3-0.3,0.4-0.4c0.1-0.2,0.2-0.3,0.3-0.5
		c0.1-0.2,0.1-0.3,0.1-0.5c0-0.1,0-0.2,0-0.3l0.6,0.8L95,107l-3.3-4.7L90.6,102.9z"/>
                <path class="st0" d="M84,104.1l1.3,2.7c-0.1,0-0.2-0.1-0.3-0.1c-0.5-0.1-0.9,0-1.4,0.3c-0.5,0.2-0.8,0.6-1.1,1
		c-0.2,0.4-0.3,0.9-0.3,1.4s0.2,1,0.4,1.5c0.3,0.5,0.6,0.9,1,1.2c0.4,0.3,0.8,0.5,1.3,0.6s0.9,0,1.4-0.3c0.5-0.2,0.8-0.6,1-1
		c0.1-0.1,0.1-0.3,0.1-0.4l0.2,0.5l0.9-0.4l-3.7-7.4L84,104.1z M86.7,110.7c-0.1,0.3-0.3,0.5-0.7,0.6c-0.3,0.2-0.6,0.2-0.9,0.2
		c-0.3-0.1-0.6-0.2-0.8-0.4c-0.2-0.2-0.4-0.5-0.6-0.8s-0.3-0.7-0.3-1s0-0.6,0.1-0.9s0.3-0.5,0.7-0.6c0.3-0.2,0.6-0.2,0.9-0.2
		c0.3,0.1,0.5,0.2,0.8,0.4c0.2,0.2,0.4,0.5,0.6,0.9s0.3,0.7,0.3,1C86.9,110.2,86.9,110.5,86.7,110.7z"/>

                <rect x="80.5" y="108.6" transform="matrix(0.9295 -0.3688 0.3688 0.9295 -35.7734 37.8159)" class="st0" width="1.1" height="7.8" />
                <path class="st0" d="M78.1,111.6c-0.1-0.2-0.2-0.4-0.4-0.6c-0.2-0.2-0.3-0.4-0.6-0.5s-0.5-0.3-0.8-0.3s-0.6,0-1,0.1
		c-0.5,0.1-0.9,0.4-1.2,0.7c-0.2,0.2-0.3,0.4-0.4,0.7l-0.2-0.7l-0.9,0.3l1.6,5.5l1.1-0.3l-0.8-2.9l0,0c-0.1-0.3-0.1-0.6-0.1-0.9
		c0-0.2,0.1-0.5,0.1-0.6c0.1-0.2,0.2-0.3,0.4-0.4c0.2-0.1,0.3-0.2,0.5-0.3c0.3-0.1,0.5-0.1,0.8,0c0.2,0.1,0.4,0.2,0.6,0.3
		c0.2,0.2,0.3,0.4,0.4,0.6s0.2,0.4,0.2,0.6l0.8,2.7l1.1-0.3l-0.9-3.1C78.3,112,78.2,111.8,78.1,111.6z"/>
                <path class="st0" d="M71.7,114.9c-0.2-0.2-0.4-0.3-0.7-0.4c-0.3-0.1-0.7-0.2-1.2-0.2s-0.8-0.1-1-0.1s-0.4-0.1-0.5-0.2
		s-0.1-0.2-0.2-0.3c0-0.2,0-0.5,0.2-0.6c0.2-0.2,0.4-0.3,0.8-0.3c0.4-0.1,0.7,0,1,0.1s0.5,0.3,0.6,0.6l1.1-0.4
		c-0.2-0.5-0.5-0.9-1-1.1s-1.1-0.3-1.8-0.2c-0.7,0.1-1.3,0.4-1.6,0.8c-0.4,0.4-0.5,0.9-0.4,1.4c0,0.3,0.2,0.5,0.3,0.7
		c0.2,0.2,0.4,0.3,0.7,0.4c0.3,0.1,0.7,0.2,1.2,0.2s0.8,0.1,1.1,0.1c0.2,0,0.4,0.1,0.5,0.2s0.2,0.2,0.2,0.3c0,0.2,0,0.4-0.2,0.6
		s-0.4,0.3-0.8,0.3c-0.3,0-0.6,0-0.9-0.1c-0.2-0.1-0.4-0.3-0.5-0.6l-1.1,0.4c0.1,0.3,0.3,0.6,0.5,0.8c0.3,0.2,0.6,0.4,0.9,0.4
		c0.4,0.1,0.8,0.1,1.2,0c0.4-0.1,0.8-0.2,1.1-0.4c0.3-0.2,0.5-0.4,0.7-0.7c0.1-0.3,0.2-0.6,0.1-0.9
		C71.9,115.3,71.8,115.1,71.7,114.9z"/>
                <polygon class="st0" points="48.9,112.5 52.6,113.1 52.2,115.5 49.1,115 49,116 52.1,116.5 51.7,118.6 48,118 47.8,119 52.6,119.8 
		53.9,112.3 49.1,111.5 	"/>
                <path class="st0" d="M45.8,113.7L45.8,113.7c-0.1,0.3-0.2,0.6-0.4,0.8c-0.1,0.2-0.3,0.4-0.5,0.5s-0.3,0.2-0.5,0.2s-0.4,0-0.6-0.1
		c-0.3-0.1-0.5-0.2-0.7-0.4c-0.1-0.2-0.2-0.4-0.3-0.6c0-0.2,0-0.5,0-0.7s0.1-0.5,0.1-0.7l0.8-2.7l-1.1-0.3l-0.9,3.1
		c0,0.2-0.1,0.4-0.1,0.6s0,0.5,0,0.7c0,0.3,0.1,0.5,0.2,0.8c0.1,0.2,0.3,0.5,0.5,0.7s0.5,0.4,0.9,0.5c0.5,0.1,1,0.2,1.4,0
		c0.3-0.1,0.5-0.2,0.7-0.4l-0.2,0.7l1,0.3l1.6-5.5l-1.1-0.3L45.8,113.7z"/>
                <path class="st0" d="M37.4,107.3c-0.7-0.3-1.2-0.4-1.8-0.3c-0.5,0.1-0.9,0.4-1.1,0.9c-0.1,0.3-0.2,0.5-0.2,0.8
		c0,0.2,0.1,0.5,0.3,0.7c0.2,0.3,0.5,0.5,0.9,0.9c0.4,0.3,0.6,0.5,0.8,0.7s0.3,0.3,0.3,0.4s0,0.2,0,0.3c-0.1,0.2-0.3,0.3-0.5,0.3
		s-0.5,0-0.8-0.2s-0.5-0.4-0.7-0.6c-0.1-0.2-0.2-0.5-0.1-0.8l-1.1-0.3c-0.1,0.3-0.1,0.6,0,1c0.1,0.3,0.2,0.6,0.5,0.9s0.6,0.5,1,0.7
		s0.8,0.3,1.2,0.3s0.7-0.1,1-0.2c0.3-0.2,0.5-0.4,0.6-0.7c0.1-0.3,0.2-0.5,0.1-0.7c0-0.2-0.1-0.5-0.3-0.7c-0.2-0.3-0.5-0.5-0.9-0.9
		c-0.4-0.3-0.6-0.5-0.8-0.7s-0.3-0.3-0.3-0.4s0-0.2,0.1-0.4c0.1-0.2,0.3-0.4,0.5-0.4s0.5,0,0.8,0.2s0.6,0.4,0.8,0.6
		c0.2,0.3,0.2,0.5,0.1,0.8l1.1,0.3c0.2-0.5,0.1-1.1-0.2-1.5C38.5,108,38,107.6,37.4,107.3z"/>
                <path class="st0" d="M33.9,106.5c0-0.3-0.1-0.6-0.2-0.9c-0.2-0.3-0.4-0.5-0.8-0.8c-0.5-0.3-0.9-0.4-1.3-0.4c-0.3,0-0.7,0.1-1,0.2
		l0.5-0.7l-0.8-0.5l-1.9,3c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.2-0.2,0.4-0.2,0.6c-0.1,0.4,0,0.8,0.3,1.1c0.2,0.4,0.6,0.7,1,0.9
		c0.6,0.4,1.1,0.5,1.6,0.5s0.9-0.2,1.3-0.6l-0.7-0.8c-0.3,0.2-0.5,0.3-0.8,0.3s-0.6-0.1-0.8-0.3c-0.4-0.3-0.6-0.5-0.7-0.8
		c0-0.2,0-0.5,0.2-0.9c0.2,0.1,0.4,0.2,0.6,0.3c0.3,0.1,0.6,0.3,0.9,0.4s0.6,0.2,0.8,0.3c0.3,0.1,0.6,0.1,0.8,0.1s0.5-0.1,0.7-0.2
		s0.4-0.3,0.5-0.6C33.8,107.1,33.9,106.8,33.9,106.5z M32.7,106.7c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.1-0.4,0.1
		c-0.1,0-0.3,0-0.4-0.1c-0.2,0-0.4-0.1-0.6-0.2s-0.5-0.2-0.7-0.3c-0.2-0.1-0.4-0.2-0.5-0.2c0.1-0.1,0.1-0.2,0.2-0.3
		c0.1-0.2,0.3-0.3,0.4-0.4s0.3-0.2,0.5-0.3c0.2-0.1,0.4-0.1,0.7-0.1c0.3,0,0.5,0.1,0.8,0.3c0.2,0.1,0.3,0.3,0.4,0.4
		c0.1,0.1,0.1,0.3,0.1,0.4C32.8,106.5,32.7,106.6,32.7,106.7z"/>
                <polygon class="st0" points="27.1,101 21.9,104.2 22.8,104.9 26.7,102.4 25.2,106.8 26,107.5 28,101.7 	" />
                <path class="st0" d="M23.9,100c0.2-0.5,0.2-1,0.1-1.4c-0.1-0.5-0.4-0.9-0.8-1.3s-0.8-0.7-1.3-0.8c-0.5-0.1-1-0.1-1.4,0.1
		c-0.5,0.2-0.9,0.5-1.4,0.9c-0.4,0.4-0.7,0.9-0.9,1.4c-0.2,0.5-0.2,1-0.1,1.4c0.1,0.5,0.4,0.9,0.8,1.3s0.8,0.7,1.3,0.8
		c0.5,0.1,1,0.1,1.4-0.1c0.5-0.2,0.9-0.5,1.4-0.9S23.8,100.5,23.9,100z M22.9,99.2c-0.1,0.5-0.3,0.9-0.7,1.3
		c-0.3,0.3-0.6,0.5-0.8,0.6c-0.3,0.1-0.6,0.2-0.9,0.1s-0.6-0.2-0.8-0.5c-0.4-0.4-0.5-0.8-0.5-1.2c0.1-0.4,0.3-0.9,0.7-1.3
		c0.4-0.4,0.8-0.7,1.3-0.7c0.5-0.1,0.9,0.1,1.3,0.5C22.8,98.4,23,98.8,22.9,99.2z"/>
                <rect x="13.8" y="98" transform="matrix(0.7646 -0.6445 0.6445 0.7646 -60.121 32.443)" class="st0" width="1.1" height="1.1" />
                <rect x="14.8" y="95.2" transform="matrix(0.764 -0.6452 0.6452 0.764 -57.6077 33.9886)" class="st0" width="5.7" height="1.1" />
                <path class="st0" d="M13.8,95.5l4.6-3.4l-0.7-0.9l-2.3,1.7c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.4,0.1-0.6,0.1
		c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.4-0.2-0.5-0.4c-0.2-0.2-0.3-0.3-0.3-0.5L11.9,93c0.1,0.2,0.2,0.3,0.3,0.5
		c0.1,0.1,0.3,0.3,0.4,0.4c0.2,0.1,0.3,0.2,0.5,0.2c0.2,0.1,0.3,0.1,0.5,0.1c0.1,0,0.2,0,0.3,0l-0.8,0.6L13.8,95.5z"/>
                <path class="st0" d="M16.4,84.6L13.8,86c0-0.1,0.1-0.2,0.1-0.3c0.1-0.5,0-0.9-0.3-1.4s-0.6-0.8-1-1S11.7,83,11.2,83s-1,0.2-1.5,0.4
		c-0.5,0.3-0.9,0.6-1.2,1S8,85.3,7.9,85.7c0,0.5,0.1,0.9,0.3,1.4c0.3,0.5,0.6,0.8,1,1c0.1,0.1,0.3,0.1,0.4,0.1l-0.5,0.3l0.5,0.9
		l7.3-3.9L16.4,84.6z M10.7,87.6c-0.3,0-0.6,0-0.9-0.1c-0.3-0.1-0.5-0.3-0.6-0.6C9,86.5,9,86.2,9,85.9c0-0.3,0.2-0.6,0.4-0.8
		c0.2-0.2,0.5-0.5,0.8-0.6c0.3-0.2,0.7-0.3,1-0.3s0.6,0,0.9,0.1c0.3,0.1,0.5,0.3,0.7,0.6c0.2,0.3,0.2,0.6,0.2,0.9s-0.2,0.5-0.4,0.8
		c-0.2,0.2-0.5,0.4-0.8,0.6l0,0C11.3,87.4,11,87.5,10.7,87.6z"/>
                <rect x="4" y="81.2" transform="matrix(0.9215 -0.3884 0.3884 0.9215 -31.134 9.4789)" class="st0" width="7.8" height="1.1" />
                <path class="st0" d="M6.8,75.3c0.3-0.1,0.6-0.2,0.9-0.2c0.2,0,0.5,0,0.6,0.1c0.2,0.1,0.3,0.2,0.4,0.3C8.8,75.6,8.9,75.8,9,76
		c0.1,0.3,0.1,0.5,0,0.8c-0.1,0.2-0.2,0.4-0.3,0.6c-0.2,0.2-0.3,0.3-0.6,0.4C7.9,78,7.7,78,7.5,78.1L4.8,79l0.4,1l3-1
		c0.2-0.1,0.4-0.1,0.6-0.2c0.2-0.1,0.4-0.2,0.6-0.4c0.2-0.2,0.4-0.3,0.5-0.6c0.1-0.2,0.2-0.5,0.3-0.8c0-0.3,0-0.6-0.1-1
		c-0.2-0.5-0.4-0.9-0.7-1.2c-0.2-0.2-0.4-0.3-0.7-0.4l0.7-0.2l-0.3-0.9L3.6,75l0.3,1.1L6.8,75.3L6.8,75.3z"/>
                <path class="st0" d="M4.6,72.8c0.3-0.1,0.5-0.2,0.7-0.3c0.2-0.2,0.3-0.4,0.4-0.7c0.1-0.3,0.2-0.7,0.2-1.2s0.1-0.8,0.1-1
		s0.1-0.4,0.2-0.5s0.2-0.1,0.3-0.2c0.2,0,0.5,0,0.6,0.2c0.2,0.2,0.3,0.4,0.4,0.8s0,0.7-0.1,1s-0.3,0.5-0.6,0.6l0.4,1.1
		c0.5-0.2,0.9-0.6,1.1-1c0.2-0.5,0.3-1.1,0.1-1.8c-0.1-0.7-0.4-1.2-0.8-1.6c-0.4-0.3-0.9-0.5-1.4-0.4c-0.3,0.1-0.5,0.2-0.7,0.3
		c-0.2,0.2-0.3,0.4-0.4,0.7C5,69.1,5,69.5,4.9,70c0,0.5-0.1,0.8-0.1,1.1c0,0.2-0.1,0.4-0.2,0.5s-0.2,0.2-0.3,0.2
		c-0.2,0-0.4,0-0.6-0.2c-0.2-0.2-0.3-0.4-0.3-0.8c-0.1-0.3,0-0.6,0.1-0.9c0.1-0.2,0.3-0.4,0.6-0.5l-0.4-1.1
		c-0.3,0.1-0.6,0.3-0.8,0.5c-0.2,0.3-0.3,0.6-0.4,0.9c-0.1,0.4-0.1,0.8,0,1.2C2.6,71.4,2.8,71.7,3,72s0.4,0.5,0.7,0.7
		C4,72.8,4.3,72.9,4.6,72.8z"/>
                <polygon class="st0" points="8.3,49.8 7.2,49.7 6.7,53.4 4.4,53.1 4.8,50 3.8,49.8 3.3,52.9 1.2,52.6 1.7,48.9 0.7,48.7 0,53.6 
		7.6,54.7 	"/>
                <path class="st0" d="M8.8,47.4l-2.9-0.8l0,0c-0.3-0.1-0.6-0.2-0.8-0.3c-0.2-0.1-0.4-0.3-0.5-0.5s-0.2-0.3-0.2-0.5s0-0.4,0.1-0.6
		c0.1-0.3,0.2-0.5,0.4-0.7c0.2-0.2,0.4-0.3,0.6-0.3s0.5-0.1,0.7,0c0.2,0,0.5,0.1,0.7,0.1l2.7,0.7l0.3-1.1l-3.1-0.8
		c-0.2,0-0.4-0.1-0.6-0.1s-0.5,0-0.7,0c-0.3,0-0.5,0.1-0.8,0.2C4.4,42.9,4.2,43,4,43.3c-0.2,0.2-0.3,0.5-0.4,0.9
		c-0.1,0.5-0.1,1,0,1.4c0.1,0.3,0.2,0.5,0.4,0.7l-0.7-0.2L3,47l5.5,1.5L8.8,47.4z"/>
                <path class="st0" d="M7.3,38.4c0.3,0.1,0.5,0.2,0.7,0.1c0.2,0,0.5-0.1,0.7-0.4C9,38,9.3,37.7,9.6,37.3s0.5-0.6,0.7-0.8
		s0.3-0.3,0.4-0.3c0.1,0,0.2,0,0.4,0.1s0.4,0.3,0.4,0.5s0,0.5-0.1,0.8c-0.2,0.3-0.4,0.6-0.6,0.8c-0.3,0.2-0.5,0.2-0.8,0.2l-0.3,1.1
		c0.6,0.1,1.1,0.1,1.5-0.2s0.8-0.7,1.1-1.4s0.4-1.2,0.3-1.8c-0.1-0.5-0.4-0.9-1-1.1c-0.3-0.1-0.5-0.2-0.8-0.1
		c-0.2,0-0.5,0.1-0.7,0.3c-0.3,0.2-0.5,0.5-0.8,0.9s-0.5,0.6-0.7,0.8s-0.3,0.3-0.4,0.3c-0.1,0-0.2,0-0.3,0c-0.2-0.1-0.3-0.3-0.3-0.5
		s0-0.5,0.2-0.8s0.4-0.5,0.6-0.7c0.2-0.1,0.5-0.2,0.8-0.1l0.3-1.1c-0.3-0.1-0.7-0.1-1,0s-0.6,0.3-0.9,0.5c-0.3,0.3-0.5,0.6-0.7,1
		s-0.3,0.8-0.3,1.2s0.1,0.7,0.2,1C6.8,38.1,7,38.3,7.3,38.4z"/>
                <path class="st0" d="M10.7,32c-0.2-0.3-0.3-0.5-0.3-0.8s0.1-0.6,0.2-0.8c0.2-0.4,0.5-0.6,0.8-0.7c0.2,0,0.5,0,0.9,0.2
		c-0.1,0.2-0.2,0.4-0.2,0.6c-0.1,0.3-0.3,0.6-0.4,1c-0.1,0.3-0.2,0.6-0.3,0.8c-0.1,0.3-0.1,0.6-0.1,0.8c0,0.3,0.1,0.5,0.2,0.7
		c0.1,0.2,0.3,0.4,0.6,0.5c0.3,0.2,0.6,0.2,0.9,0.2c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.4,0.7-0.8c0.3-0.5,0.4-0.9,0.4-1.3
		c0-0.3-0.1-0.7-0.2-1l0.7,0.4l0.5-0.8L13,29c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.4-0.1-0.6-0.2c-0.4,0-0.8,0.1-1.1,0.3
		c-0.3,0.2-0.6,0.6-0.9,1c-0.3,0.6-0.5,1.1-0.4,1.6c0,0.5,0.2,0.9,0.6,1.3L10.7,32z M12.8,30.9c0.1-0.2,0.1-0.4,0.2-0.6
		c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.2,0.4,0.4c0.1,0.1,0.2,0.3,0.3,0.5s0.1,0.4,0.1,0.7s-0.1,0.5-0.3,0.8
		c-0.1,0.2-0.3,0.4-0.4,0.4c-0.1,0.1-0.3,0.1-0.4,0.1c-0.1,0-0.3,0-0.4-0.1c-0.1-0.1-0.2-0.2-0.3-0.3s-0.1-0.2-0.1-0.4
		c0-0.1,0-0.3,0-0.4c0-0.2,0.1-0.4,0.2-0.6C12.6,31.4,12.7,31.1,12.8,30.9z"/>
                <polygon class="st0" points="18.3,27.6 15,22.5 14.3,23.4 16.9,27.2 12.5,25.8 11.8,26.7 17.6,28.5 	" />
                <path class="st0" d="M17.8,23.6c0.4,0.4,0.9,0.7,1.4,0.8c0.5,0.1,1,0.2,1.4,0c0.5-0.1,0.9-0.4,1.3-0.8s0.6-0.9,0.7-1.3
		c0.1-0.5,0.1-1-0.1-1.4c-0.2-0.5-0.5-0.9-0.9-1.3s-0.9-0.7-1.4-0.8c-0.5-0.1-1-0.2-1.4,0c-0.5,0.1-0.9,0.4-1.3,0.8
		s-0.6,0.9-0.7,1.3c-0.1,0.5-0.1,1,0.1,1.4S17.4,23.2,17.8,23.6z M18.3,20.2c0.4-0.4,0.8-0.6,1.2-0.5c0.4,0,0.9,0.3,1.3,0.7
		c0.4,0.4,0.7,0.8,0.8,1.3c0.1,0.5-0.1,0.9-0.4,1.3c-0.4,0.4-0.8,0.6-1.2,0.5c-0.5,0-0.9-0.3-1.3-0.7c-0.3-0.3-0.5-0.5-0.6-0.8
		s-0.2-0.6-0.1-0.9C17.9,20.7,18.1,20.5,18.3,20.2z"/>
                <rect x="22.8" y="15.2" transform="matrix(0.75 -0.6614 0.6614 0.75 -6.1124 19.9522)" class="st0" width="1.1" height="5.7" />
                <rect x="19.9" y="14.3" transform="matrix(0.7497 -0.6617 0.6617 0.7497 -4.7065 17.2767)" class="st0" width="1.1" height="1.1" />
                <path class="st0" d="M27.9,18.1l-1.8-2.3c-0.1-0.2-0.3-0.4-0.3-0.6c-0.1-0.2-0.1-0.4-0.2-0.6c0-0.2,0-0.4,0.1-0.6
		c0.1-0.2,0.2-0.4,0.4-0.6c0.2-0.2,0.3-0.3,0.5-0.3L26,12.3c-0.2,0.1-0.3,0.2-0.4,0.3s-0.3,0.3-0.4,0.4c-0.1,0.2-0.2,0.3-0.2,0.5
		c-0.1,0.2-0.1,0.3-0.1,0.5c0,0.1,0,0.2,0,0.3l-0.6-0.8l-0.8,0.6l3.5,4.6L27.9,18.1z"/>
                <path class="st0" d="M34.5,16.6L33,14c0.1,0,0.2,0.1,0.3,0.1c0.5,0.1,0.9-0.1,1.4-0.3c0.5-0.3,0.8-0.6,1-1s0.3-0.9,0.3-1.4
		s-0.2-1-0.5-1.5s-0.6-0.9-1-1.2s-0.8-0.5-1.3-0.5s-0.9,0.1-1.4,0.3c-0.5,0.3-0.8,0.6-1,1c-0.1,0.1-0.1,0.3-0.1,0.4l-0.3-0.5
		l-0.9,0.5l4,7.2L34.5,16.6z M31.4,10.9c-0.1-0.3,0-0.6,0.1-0.9c0.1-0.3,0.3-0.5,0.6-0.6c0.3-0.2,0.6-0.2,0.9-0.2
		c0.3,0,0.6,0.2,0.8,0.4s0.5,0.5,0.6,0.8c0.2,0.3,0.3,0.7,0.4,1c0,0.3,0,0.6-0.1,0.9c-0.1,0.3-0.3,0.5-0.6,0.7s-0.6,0.2-0.9,0.2
		c-0.3,0-0.6-0.2-0.8-0.4s-0.5-0.5-0.7-0.8l0,0C31.5,11.6,31.4,11.3,31.4,10.9z"/>

                <rect x="36.5" y="4.1" transform="matrix(0.9131 -0.4078 0.4078 0.9131 -4.965379e-02 15.8023)" class="st0" width="1.1" height="7.8" />
                <path class="st0" d="M42.8,10.1c0.5-0.2,0.9-0.4,1.1-0.7c0.2-0.2,0.3-0.4,0.4-0.7l0.2,0.7l1-0.4l-1.9-5.4l-1,0.4l1,2.8l0,0
		c0.1,0.3,0.2,0.6,0.2,0.9c0,0.2,0,0.5-0.1,0.6c-0.1,0.2-0.2,0.3-0.3,0.4C43.1,8.9,43,9,42.8,9c-0.3,0.1-0.5,0.1-0.8,0.1
		c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.2-0.3-0.3-0.4-0.6c-0.1-0.2-0.2-0.4-0.3-0.6l-0.9-2.7l-1.1,0.4l1,3c0.1,0.2,0.1,0.4,0.2,0.6
		c0.1,0.2,0.2,0.4,0.4,0.6c0.2,0.2,0.4,0.4,0.6,0.5c0.2,0.1,0.5,0.2,0.8,0.3C42.1,10.3,42.5,10.2,42.8,10.1z"/>
                <path class="st0" d="M46.3,5.2c0.2,0.2,0.4,0.3,0.7,0.4s0.7,0.1,1.2,0.2c0.5,0,0.8,0.1,1,0.1s0.4,0.1,0.5,0.2s0.1,0.2,0.2,0.3
		C50,6.6,50,6.8,49.8,7S49.4,7.3,49,7.4c-0.4,0.1-0.7,0.1-1-0.1c-0.3-0.1-0.5-0.3-0.6-0.6l-1.1,0.4c0.2,0.5,0.6,0.9,1.1,1.1
		c0.5,0.2,1.1,0.2,1.8,0.1c0.7-0.2,1.2-0.4,1.6-0.8c0.3-0.4,0.4-0.9,0.3-1.4c-0.1-0.3-0.2-0.5-0.3-0.7C50.6,5.2,50.3,5.1,50,5
		s-0.7-0.1-1.2-0.2c-0.5,0-0.8,0-1.1-0.1c-0.2,0-0.4-0.1-0.5-0.2S47,4.3,47,4.2c0-0.2,0-0.4,0.2-0.6c0.2-0.2,0.4-0.3,0.8-0.3
		c0.3-0.1,0.6,0,0.9,0.1c0.2,0.1,0.4,0.3,0.5,0.6l1.1-0.4c-0.1-0.3-0.3-0.6-0.6-0.8S49.3,2.5,49,2.4c-0.4-0.1-0.8,0-1.2,0
		c-0.4,0.1-0.8,0.2-1.1,0.5c-0.3,0.2-0.5,0.5-0.6,0.8S45.9,4.3,46,4.6C46.1,4.9,46.2,5.1,46.3,5.2z"/>
                <circle class="st0" cx="59.7" cy="4.1" r="1.6" />
                <path class="st0" d="M60.7,114.5c-0.9,0-1.6,0.7-1.6,1.6s0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6S61.5,114.5,60.7,114.5z" />
                <path class="st0" d="M116.3,58.6c-0.9,0-1.6,0.7-1.6,1.6c0,0.9,0.7,1.6,1.6,1.6s1.6-0.7,1.6-1.6S117.2,58.6,116.3,58.6z" />
                <path class="st0" d="M3,61.8c0,0.9,0.7,1.6,1.6,1.6s1.6-0.7,1.6-1.6c0-0.9-0.7-1.6-1.6-1.6S3,60.9,3,61.8z" />
            </g>
        </svg>
    )
}