import React from 'react';
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router';

const Footer = (props) => {
    //let className = "" + props.textColor;
    const { t, i18n } = useTranslation();
    const location = useLocation();
    let footerBackground = "";
    let footerText = "";
    let footerBorder = "";
    let footerPlaceholder = "";
    let footerHoverButtonBg = "";
    let footerHoverButtonText = "";


    const whitefooter = () => {
        footerBackground = "bg-whiteEllie ";
        footerText = "text-greenEllie-dark ";
        footerBorder = "border-greenEllie-dark ";
        footerPlaceholder = "placeholder-greenEllie-dark ";
        footerHoverButtonBg = "bg-greenEllie-dark ";
        footerHoverButtonText = "text-whiteEllie ";
    }

    const greenfooter = () => {
        footerBackground = "bg-greenEllie-dark ";
        footerText = "text-whiteEllie ";
        footerBorder = "border-whiteEllie ";
        footerPlaceholder = "placeholder-whiteEllie ";
        footerHoverButtonBg = "bg-whiteEllie ";
        footerHoverButtonText = "text-greenEllie-dark ";
    }

    if (location.pathname.includes('/services')) {
        whitefooter();
    } else if (location.pathname.includes('/portfolio')) {
        greenfooter();
    } else if (location.pathname.includes('/about')) {
        greenfooter();
    } else if (location.pathname.includes('/contact')) {
        whitefooter();
    } else {
        greenfooter();
    }

    return (

        
        <footer className={footerBackground}>
            <div className={"flex flex-col lg:flex-row justify-between border-t py-4 mx-10 lg:mx-20 " + footerBorder}>
                <form className="py-2 w-full lg:w-2/3 flex flex-col md:flex-row md:items-center" method="post" action="https://newsletter.infomaniak.com/external/submit">
                        <label className={"font-display font-bold italic justify-start mb-2 md:mb-0 " + footerText}>{t("newsletter")}</label>
                        <div className="flex flex-row w-full">
                            <input className={"font-display italic border bg-transparent md:ml-8 py-2 px-6 w-1/2 lg:w-1/3 " + footerText + footerBorder + footerPlaceholder} type="email" name="email" placeholder={t("yourEmail")} />
                            <input type="hidden" name="key" value="eyJpdiI6Illhekc1RWV4bzU4RldhTVZHcUtZNGliQlFLWU9vNzdyRmVIR3V2dVJqdUk9IiwidmFsdWUiOiJBOTNCTHREdGFxQzZtZlIzYWxwRmxKWXBNWmVrc3A3VlRjd3lYWktpdXpJPSIsIm1hYyI6ImYwMzgxYTE1NWZmYjQzNmQzZTA0ZTljNjBiNDY0NDc3NjFhMTFiYzY0Y2VmNjNkY2VhMDkyNzIwYjEyYTNiZWIifQ=="/><input type="hidden" name="webform_id" value="11561"/>
                            <button type="submit" className={"font-body font-bold bg-transparent border border-l-0 py-2 px-16 w-1/2 lg:w-1/3 " + footerText + footerBorder + "hover:" + footerHoverButtonBg + "hover:" + footerHoverButtonText}>
                                {t("send")}
                            </button>
                        </div>
                </form>
                <p className={"text-sm py-2 lg:w-1/3 align-middle md:justify-self-end text-right " + footerText}>{t("footer")}</p>
            </div>
        </footer>

    );
}
export default Footer;