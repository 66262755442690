import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { updateCurrentIndex } from '../../redux/reducers/portfolioSlice';
import store from '../../redux/store';
import SeeMoreFr from '../../images/see-more-fr';
import ArrowMore from '../../images/arrow-more';
import SeeMoreEn from '../../images/see-more-en';

const PortfolioListItem = (props) => {
    const { i18n } = useTranslation();

    const updateCurrentPortfolioItemIndex = () => {
        window.scrollTo(0, 0);
        store.dispatch(updateCurrentIndex(props.index))
    }

    console.log(i18n.language)

    const getBtnTxt = () => {
        if (i18n.language === 'fr') {
            return <SeeMoreFr />
        } else {
            return <SeeMoreEn/>
        }
    }

    const getBtnMore = () => {
        return <button className="absolute -right-10 -bottom-10 opacity-0 group-hover:opacity-100 filter group-hover:brightness-100 more-btn">
            {getBtnTxt()}
            <ArrowMore />
        </button>
    }

    return (
        <Link to={"/portfolio/" + props.portfolioItem.title[i18n.language]} onClick={updateCurrentPortfolioItemIndex}>
            <div className="group cursor-pointer pb-20">
                <div className="flex flex-col relative h-full">
                    <img className="object-contain filter group-hover:brightness-25" src={props.portfolioItem.mainImage.asset.url} alt="Display" />
                    {getBtnMore()}
                </div>
                <p className="group-hover:opacity-20 uppercase font-bold pt-6 text-whiteEllie">{props.portfolioItem.title[i18n.language]}</p>
                <p className="group-hover:opacity-20 pt-2 text-whiteEllie">{props.portfolioItem.description[i18n.language]}</p>
            </div>
        </Link>
    );
}
export default PortfolioListItem;