export default function SeeMoreEn() {
    return (
        <svg width="120" height="120" className='absolute bottom-0 right-0 more-text'>
            <g>
                <circle class="st0" cx="55.2" cy="3" r="1.6" />
                <circle class="st0" cx="95.5" cy="102.9" r="1.6" />
                <circle class="st0" cx="31" cy="107.5" r="1.6" />
                <circle class="st0" cx="113.3" cy="40.1" r="1.6" />
                <circle class="st0" cx="3.7" cy="48.9" r="1.6" />
                <path class="st0" d="M70,3.3c0-0.2,0.1-0.4,0.2-0.6s0.2-0.4,0.3-0.5C70.6,2,70.8,1.9,71,1.8s0.4-0.1,0.7-0.1c0.2,0,0.4,0.1,0.6,0.2
                l0.2-1c-0.2-0.1-0.3-0.1-0.5-0.1s-0.4,0-0.6,0S71,0.9,70.9,1c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1,0.1-0.1,0.2-0.2,0.2l0.2-1l-1-0.2
                L68.3,6l1.1,0.2L70,3.3z"/>
                <path class="st0" d="M76.5,7.7c0.5-0.1,1-0.4,1.3-0.9l-1-0.6c-0.2,0.2-0.5,0.4-0.8,0.5s-0.6,0.1-0.9,0c-0.5-0.2-0.9-0.4-1-0.9
                C74,5.4,73.9,5,74,4.5l3.6,1.1l0,0l0.5,0.2c0.2-0.7,0.3-1.3,0.3-1.8c-0.1-0.5-0.2-1-0.6-1.4c-0.3-0.4-0.8-0.7-1.3-0.8
                c-0.5-0.2-1-0.3-1.5-0.1c-0.5,0.1-0.9,0.4-1.3,0.8S73.1,3.4,72.9,4c-0.2,0.6-0.2,1.1-0.1,1.6s0.3,0.9,0.7,1.3
                c0.3,0.4,0.8,0.6,1.4,0.8C75.4,7.8,76,7.8,76.5,7.7z M75,2.9c0.4-0.3,0.8-0.3,1.4-0.2c0.5,0.1,0.8,0.4,1,0.8
                c0.1,0.3,0.1,0.7,0.1,1.1l-3-0.9C74.5,3.3,74.7,3,75,2.9z"/>
                <path class="st0" d="M82.4,9.3l-0.3,0.8l0.9,0.4l1.4-3.3c0.1-0.2,0.2-0.4,0.2-0.6c0.1-0.2,0.1-0.4,0.1-0.6c0-0.4-0.2-0.8-0.5-1.1
                c-0.3-0.3-0.7-0.6-1.1-0.8c-0.6-0.3-1.2-0.3-1.7-0.2C81,4,80.5,4.2,80.2,4.6L81,5.3c0.2-0.3,0.5-0.4,0.8-0.4s0.6,0,0.9,0.1
                c0.4,0.2,0.7,0.4,0.8,0.7c0.1,0.2,0,0.5-0.1,0.9c-0.2-0.1-0.4-0.1-0.6-0.2c-0.4-0.1-0.7-0.2-1-0.3C81.5,6,81.2,6,80.9,5.9
                c-0.3,0-0.6,0-0.8,0.1c-0.3,0.1-0.5,0.2-0.7,0.3C79.2,6.5,79.1,6.7,79,7c-0.1,0.3-0.2,0.6-0.1,0.9c0,0.3,0.2,0.6,0.4,0.8
                c0.2,0.3,0.5,0.5,0.9,0.6c0.5,0.2,1,0.3,1.4,0.2C81.8,9.7,82.1,9.5,82.4,9.3z M80.6,8.7c-0.2-0.1-0.4-0.2-0.5-0.3S79.9,8.1,79.9,8
                s0-0.3,0.1-0.4s0.1-0.2,0.3-0.3c0.1-0.1,0.2-0.1,0.4-0.1c0.1,0,0.3,0,0.5,0s0.4,0.1,0.6,0.1c0.2,0.1,0.5,0.1,0.8,0.2
                c0.2,0,0.4,0.1,0.6,0.2c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.2-0.2,0.4-0.3,0.5c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.1-0.4,0.2-0.7,0.2
                C81.2,8.9,80.9,8.8,80.6,8.7z"/>
                <path class="st0" d="M87.7,12.9c0.2,0,0.3-0.1,0.5-0.1l-0.3,0.5l0.9,0.5L92.6,7l-1-0.6l-1.2,2.1c0-0.1,0-0.2-0.1-0.3
                c-0.2-0.4-0.5-0.8-1-1c-0.5-0.3-1-0.4-1.4-0.4c-0.5,0-0.9,0.2-1.3,0.5S85.8,8,85.5,8.5C85.2,9,85,9.5,85,10s0,1,0.2,1.4
                s0.5,0.8,1,1.1C86.8,12.8,87.2,12.9,87.7,12.9z M86.3,11c-0.1-0.3-0.1-0.6-0.1-0.9s0.2-0.7,0.4-1c0.2-0.3,0.4-0.6,0.7-0.8
                c0.3-0.2,0.5-0.3,0.8-0.4c0.3,0,0.6,0,0.9,0.2c0.3,0.2,0.5,0.4,0.6,0.7s0.1,0.6,0.1,0.9c-0.1,0.3-0.2,0.7-0.4,1l0,0
                c-0.2,0.3-0.4,0.6-0.7,0.8c-0.2,0.2-0.5,0.3-0.8,0.4c-0.3,0-0.6,0-0.9-0.2S86.4,11.3,86.3,11z"/>
                <path class="st0" d="M101.3,16.9c0-0.5-0.3-1-0.7-1.4c-0.3-0.3-0.6-0.4-1-0.5c-0.3-0.1-0.7-0.1-1,0c0.1-0.3,0.1-0.6,0-0.9
                c-0.1-0.3-0.3-0.6-0.6-0.9c-0.3-0.3-0.6-0.4-1-0.5c-0.3-0.1-0.5,0-0.8,0l0.4-0.5l-0.8-0.7L92.1,16l0.9,0.7l2.4-2.7
                c0.3-0.3,0.6-0.5,0.9-0.5s0.6,0.1,0.9,0.3s0.4,0.5,0.4,0.8s-0.1,0.6-0.4,1l-2.4,2.7l0.8,0.7l2.4-2.7l0,0c0.2-0.2,0.4-0.4,0.6-0.4
                c0.2-0.1,0.4-0.1,0.6,0c0.2,0,0.4,0.1,0.5,0.3c0.3,0.2,0.4,0.5,0.4,0.8s-0.1,0.6-0.4,1l-2.4,2.7l0.8,0.7l2.6-2.9
                C101.2,17.9,101.4,17.4,101.3,16.9z"/>
                <path class="st0" d="M100.1,21.6c-0.2,0.5-0.3,1-0.2,1.5c0.1,0.5,0.3,1,0.7,1.4c0.4,0.4,0.8,0.7,1.3,0.9c0.5,0.2,1,0.2,1.5,0.1
                s1-0.4,1.5-0.8s0.8-0.8,1-1.3s0.3-1,0.2-1.5c-0.1-0.5-0.3-1-0.7-1.4c-0.4-0.4-0.8-0.7-1.3-0.9c-0.5-0.2-1-0.2-1.5-0.1
                s-1,0.4-1.5,0.8S100.3,21.1,100.1,21.6z M101.9,21.2c0.3-0.3,0.6-0.4,0.9-0.5s0.6-0.1,0.9,0s0.6,0.3,0.8,0.6
                c0.4,0.4,0.5,0.9,0.4,1.3s-0.4,0.9-0.8,1.3c-0.5,0.4-0.9,0.6-1.4,0.6s-0.9-0.2-1.2-0.6c-0.4-0.4-0.5-0.9-0.4-1.3
                C101.1,22,101.4,21.6,101.9,21.2z"/>
                <path class="st0" d="M103.9,28.6l2.4-1.7c0.2-0.1,0.4-0.2,0.6-0.3c0.2-0.1,0.4-0.1,0.6-0.1s0.4,0,0.6,0.1s0.4,0.2,0.5,0.4
                c0.1,0.2,0.3,0.4,0.3,0.5l0.9-0.6c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1-0.2-0.3-0.3-0.4-0.4s-0.3-0.2-0.5-0.3c-0.2-0.1-0.3-0.1-0.5-0.1
                c-0.1,0-0.2,0-0.3,0l0.8-0.5l-0.6-0.8l-4.8,3.3L103.9,28.6z"/>
                <path class="st0" d="M108,29.5c-0.5,0.3-0.9,0.6-1.2,1.1c-0.3,0.4-0.4,0.9-0.4,1.4s0.1,1,0.4,1.5s0.6,0.9,1.1,1.1
                c0.5,0.3,1,0.4,1.6,0.3l-0.2-1.1c-0.3,0-0.6-0.1-0.9-0.2c-0.3-0.1-0.5-0.4-0.6-0.7c-0.3-0.5-0.3-0.9-0.1-1.3
                c0.1-0.3,0.4-0.6,0.8-0.9l1.8,3.3l0,0l0.3,0.5c0.6-0.3,1.1-0.7,1.5-1.1c0.3-0.4,0.5-0.9,0.6-1.4c0-0.5-0.1-1-0.4-1.5
                s-0.6-0.9-1.1-1.1c-0.4-0.3-0.9-0.4-1.4-0.4C109.1,29.1,108.6,29.2,108,29.5z M110.2,30.2c0.4,0.1,0.8,0.4,1.1,0.9
                c0.2,0.4,0.3,0.9,0.1,1.2c-0.1,0.3-0.4,0.6-0.7,0.8l-1.5-2.8C109.5,30.2,109.9,30.1,110.2,30.2z"/>
                <path class="st0" d="M112.5,49.7l2.9-0.5c0.2,0,0.4-0.1,0.7,0c0.2,0,0.4,0.1,0.6,0.1c0.2,0.1,0.4,0.2,0.5,0.4
                c0.2,0.2,0.3,0.4,0.3,0.6c0.1,0.2,0.1,0.4,0.1,0.6l1-0.2c0-0.2,0-0.4-0.1-0.5c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.2-0.2-0.3-0.3-0.4
                c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1,0-0.2-0.1-0.3-0.1l1-0.2l-0.2-1l-5.8,1L112.5,49.7z"/>
                <path class="st0" d="M117.5,52.4c-0.5-0.2-1-0.3-1.7-0.2c-0.6,0-1.1,0.2-1.6,0.5c-0.4,0.3-0.8,0.6-1,1.1s-0.3,1-0.3,1.6
                s0.2,1.1,0.5,1.5s0.7,0.8,1.3,0.9l0.3-1.1c-0.3-0.1-0.6-0.3-0.7-0.6c-0.2-0.2-0.3-0.5-0.3-0.9c0-0.5,0.1-1,0.4-1.3s0.7-0.4,1.1-0.5
                l0.3,3.8l0,0v0.6c0.7,0,1.3-0.1,1.8-0.4c0.5-0.2,0.9-0.6,1.1-1s0.3-1,0.3-1.5c0-0.6-0.2-1.1-0.5-1.5
                C118.4,52.9,118,52.6,117.5,52.4z M117.8,56.1c-0.2,0.2-0.6,0.3-1,0.4l-0.2-3.1c0.4,0,0.7,0.1,1,0.3c0.4,0.3,0.6,0.7,0.6,1.2
                C118.2,55.4,118.1,55.8,117.8,56.1z"/>
                <path class="st0" d="M113.1,63.9l3.6,0.2c0.2,0,0.5,0,0.7,0s0.4-0.1,0.6-0.1c0.4-0.2,0.7-0.4,0.9-0.8c0.2-0.4,0.3-0.8,0.3-1.4
                c0-0.7-0.1-1.2-0.4-1.6c-0.3-0.4-0.7-0.7-1.2-0.9l-0.4,1c0.3,0.1,0.5,0.3,0.7,0.6c0.1,0.3,0.2,0.5,0.2,0.9c0,0.5-0.2,0.8-0.4,1
                c-0.2,0.2-0.5,0.2-0.9,0.2c0-0.2,0-0.4-0.1-0.7c0-0.4-0.1-0.7-0.1-1s-0.1-0.6-0.2-0.9c-0.1-0.3-0.2-0.6-0.3-0.8
                c-0.1-0.2-0.3-0.4-0.5-0.5s-0.5-0.2-0.8-0.2s-0.6,0-0.9,0.2c-0.3,0.1-0.5,0.3-0.7,0.6s-0.3,0.6-0.3,1.1s0.1,1,0.3,1.4
                c0.2,0.3,0.4,0.5,0.7,0.8H113L113.1,63.9z M114.2,60.4c0.1-0.2,0.2-0.3,0.3-0.3c0.1-0.1,0.3-0.1,0.4-0.1c0.2,0,0.3,0,0.4,0.1
                s0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.3,0.2,0.4c0,0.2,0.1,0.4,0.1,0.6c0,0.2,0.1,0.5,0.1,0.8c0,0.2,0,0.4,0.1,0.6c-0.1,0-0.2,0-0.3,0
                c-0.2,0-0.4-0.1-0.6-0.1c-0.2-0.1-0.4-0.2-0.5-0.3c-0.2-0.1-0.3-0.3-0.4-0.6c-0.1-0.2-0.1-0.5-0.1-0.8
                C114,60.8,114.1,60.6,114.2,60.4z"/>
                <path class="st0" d="M117.9,70c0.3-0.3,0.5-0.8,0.6-1.3s0-1-0.2-1.5c-0.2-0.4-0.5-0.8-0.9-1.1c-0.4-0.3-0.9-0.5-1.5-0.6
                s-1.1-0.1-1.6,0.1c-0.5,0.1-0.9,0.4-1.2,0.7c-0.3,0.3-0.5,0.8-0.6,1.3c-0.1,0.6,0,1,0.2,1.5c0.1,0.1,0.2,0.3,0.3,0.4l-0.6-0.1
                l-0.2,1l7.7,1.3l0.2-1.1l-2.3-0.4C117.7,70.1,117.8,70.1,117.9,70z M113.4,68c0.1-0.4,0.2-0.6,0.4-0.8c0.2-0.2,0.5-0.3,0.8-0.4
                s0.7-0.1,1.1,0s0.7,0.2,1,0.3c0.3,0.2,0.5,0.4,0.6,0.7c0.1,0.3,0.2,0.6,0.1,1c-0.1,0.3-0.2,0.6-0.4,0.8c-0.2,0.2-0.5,0.3-0.8,0.4
                s-0.7,0.1-1.1,0s-0.7-0.2-1-0.3c-0.3-0.2-0.5-0.4-0.6-0.6C113.4,68.7,113.4,68.3,113.4,68z"/>
                <path class="st0" d="M110.8,75.4l3.4,1.3l0,0c0.4,0.1,0.6,0.4,0.8,0.6c0.2,0.3,0.2,0.6,0,0.9c-0.1,0.3-0.3,0.6-0.6,0.7
                c-0.3,0.1-0.7,0.1-1-0.1l-3.4-1.3l-0.4,1.1l3.4,1.3l0,0c0.3,0.1,0.5,0.2,0.6,0.4c0.1,0.2,0.2,0.4,0.3,0.6c0,0.2,0,0.4-0.1,0.6
                c-0.1,0.3-0.3,0.6-0.6,0.7c-0.3,0.1-0.6,0.1-1,0l-3.4-1.3l-0.4,1.1l3.7,1.4c0.6,0.2,1.1,0.2,1.6,0.1c0.5-0.2,0.8-0.6,1.1-1.2
                c0.1-0.4,0.2-0.7,0.1-1.1c0-0.3-0.2-0.6-0.4-0.9c0.3,0,0.6-0.2,0.9-0.4c0.3-0.2,0.5-0.5,0.6-0.9s0.2-0.7,0.1-1.1
                c0-0.3-0.1-0.5-0.3-0.8l0.6,0.2l0.4-0.9l-5.5-2.1L110.8,75.4z"/>
                <path class="st0" d="M110.4,84.3c-0.5-0.3-1-0.5-1.6-0.5c-0.5,0-1,0.1-1.4,0.3c-0.4,0.2-0.8,0.6-1.1,1.1c-0.3,0.5-0.4,1-0.4,1.5
                s0.2,1,0.5,1.4s0.7,0.8,1.2,1.1c0.5,0.3,1,0.5,1.6,0.5c0.5,0,1-0.1,1.4-0.3c0.4-0.2,0.8-0.6,1.1-1.1c0.3-0.5,0.4-1,0.4-1.5
                s-0.2-1-0.5-1.4C111.3,84.9,110.9,84.5,110.4,84.3z M110.7,87.7c-0.3,0.5-0.6,0.8-1.1,0.8c-0.5,0.1-0.9-0.1-1.5-0.4
                c-0.5-0.3-0.9-0.7-1.1-1.1s-0.2-0.9,0.1-1.4c0.3-0.5,0.6-0.7,1.1-0.8c0.5-0.1,1,0.1,1.5,0.4c0.3,0.2,0.6,0.4,0.8,0.7
                c0.2,0.3,0.3,0.5,0.3,0.8C111,87.1,110.9,87.4,110.7,87.7z"/>
                <path class="st0" d="M103.5,89.8l2.4,1.7c0.2,0.1,0.4,0.3,0.5,0.4c0.1,0.2,0.3,0.3,0.3,0.5c0.1,0.2,0.1,0.4,0.1,0.6
                s-0.1,0.4-0.2,0.7c-0.1,0.2-0.3,0.4-0.4,0.5l0.9,0.6c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.2,0.2-0.3,0.2-0.5c0.1-0.2,0.1-0.4,0.1-0.5
                c0-0.2,0-0.4-0.1-0.6c0-0.1-0.1-0.2-0.1-0.3l0.8,0.6l0.6-0.8l-4.8-3.4L103.5,89.8z"/>
                <path class="st0" d="M104.1,94c-0.5-0.4-0.9-0.7-1.4-0.8c-0.5-0.1-1-0.1-1.5,0.1s-0.9,0.5-1.3,0.9s-0.6,0.9-0.7,1.4s0,1.1,0.2,1.6
                l1-0.6c-0.1-0.3-0.2-0.6-0.1-0.9c0-0.3,0.2-0.6,0.4-0.8c0.4-0.4,0.8-0.6,1.2-0.6c0.4,0,0.8,0.2,1.2,0.5l-2.5,2.8l0,0l-0.4,0.4
                c0.5,0.5,1,0.8,1.5,1s1,0.2,1.5,0.1s0.9-0.4,1.3-0.9c0.4-0.4,0.6-0.9,0.7-1.4s0-1-0.2-1.5C104.9,94.9,104.6,94.4,104.1,94z
                 M103.8,97.6c-0.3,0.4-0.7,0.6-1.1,0.5c-0.3,0-0.6-0.2-1-0.4l2.1-2.4c0.3,0.3,0.4,0.6,0.5,0.9C104.3,96.7,104.2,97.1,103.8,97.6z"
                />
                <path class="st0" d="M86.8,105.2l1.5,2.5c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.1,0.4,0.1,0.6s-0.1,0.4-0.2,0.6s-0.3,0.4-0.4,0.5
                c-0.2,0.1-0.4,0.2-0.6,0.3l0.5,0.9c0.2-0.1,0.3-0.1,0.5-0.3c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.1,0.2-0.3,0.3-0.5
                c0.1-0.2,0.1-0.3,0.2-0.5c0-0.1,0-0.2,0-0.3l0.5,0.8l0.9-0.5l-2.9-5L86.8,105.2z"/>
                <path class="st0" d="M84.6,107.9c-0.4-0.3-0.9-0.5-1.4-0.5s-1,0-1.5,0.3c-0.5,0.2-0.9,0.6-1.2,1c-0.3,0.5-0.4,1-0.4,1.5l1.2-0.1
                c0-0.3,0.1-0.6,0.3-0.9c0.2-0.3,0.4-0.5,0.7-0.6c0.5-0.2,0.9-0.2,1.3,0c0.3,0.2,0.6,0.5,0.9,0.9l-2.9,1.3l0,0l-1,0.5
                c0.3,0.7,0.6,1.2,1,1.5c0.4,0.4,0.8,0.6,1.3,0.7s1,0,1.6-0.3c0.5-0.2,0.9-0.6,1.2-1c0.3-0.4,0.4-0.9,0.5-1.4c0-0.5-0.1-1.1-0.4-1.7
                C85.4,108.7,85,108.2,84.6,107.9z M84.8,111.4c-0.1,0.4-0.4,0.8-0.9,1s-0.9,0.2-1.2,0c-0.3-0.1-0.5-0.4-0.8-0.8l2.9-1.3
                C84.9,110.7,84.9,111,84.8,111.4z"/>
                <path class="st0" d="M78.3,114.3c-0.2,0.2-0.4,0.4-0.7,0.5c-0.5,0.1-0.8,0.1-1.1,0c-0.2-0.1-0.4-0.4-0.5-0.7
                c0.2-0.1,0.4-0.2,0.6-0.3c0.3-0.2,0.6-0.3,0.9-0.5s0.6-0.3,0.8-0.5c0.3-0.2,0.5-0.4,0.6-0.6s0.2-0.4,0.3-0.7c0-0.2,0-0.5-0.1-0.8
                s-0.3-0.6-0.5-0.8c-0.2-0.2-0.5-0.3-0.8-0.4s-0.7,0-1.1,0.1c-0.5,0.2-0.9,0.4-1.2,0.7c-0.2,0.2-0.4,0.6-0.5,0.9l-0.3-0.8l-1,0.3
                l1.1,3.4c0.1,0.2,0.2,0.4,0.2,0.6c0.1,0.2,0.2,0.4,0.3,0.5c0.3,0.3,0.6,0.5,1.1,0.5c0.4,0.1,0.9,0,1.4-0.2c0.6-0.2,1.1-0.5,1.4-0.9
                s0.4-0.9,0.4-1.4h-1.1C78.6,113.8,78.5,114.1,78.3,114.3z M75.6,111.6c0.1-0.2,0.2-0.4,0.4-0.6c0.2-0.2,0.4-0.3,0.7-0.4
                c0.2-0.1,0.4-0.1,0.6-0.1s0.3,0.1,0.4,0.2s0.2,0.2,0.2,0.4c0,0.1,0.1,0.3,0,0.4c0,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.2,0.2-0.3,0.3
                c-0.2,0.1-0.3,0.2-0.6,0.3c-0.2,0.1-0.5,0.2-0.7,0.4c-0.2,0.1-0.4,0.2-0.5,0.3c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.2-0.1-0.4-0.1-0.6
                C75.5,112,75.5,111.8,75.6,111.6z"/>
                <path class="st0" d="M72.5,112.5c-0.3-0.4-0.7-0.7-1.1-0.9c-0.4-0.2-0.9-0.2-1.5-0.1s-1,0.3-1.3,0.7c-0.1,0.1-0.2,0.2-0.3,0.4
                l-0.1-0.6l-1,0.2l1.5,7.6l1.1-0.2l-0.4-2.3c0.1,0,0.1,0.1,0.2,0.1c0.4,0.2,0.9,0.2,1.4,0.1s1-0.3,1.3-0.7c0.3-0.3,0.6-0.8,0.7-1.3
                s0.1-1,0-1.6C73,113.4,72.8,112.9,72.5,112.5z M71.9,115.3c-0.1,0.3-0.2,0.6-0.4,0.8c-0.2,0.2-0.5,0.4-0.9,0.4
                c-0.3,0.1-0.6,0-0.9-0.1s-0.5-0.3-0.6-0.6c-0.2-0.3-0.3-0.6-0.4-1s-0.1-0.8,0-1.1s0.2-0.6,0.4-0.8c0.2-0.2,0.5-0.4,0.8-0.4
                c0.4-0.1,0.7,0,0.9,0.1c0.3,0.1,0.5,0.3,0.7,0.6c0.2,0.3,0.3,0.6,0.4,1C72,114.6,72,114.9,71.9,115.3z"/>
                <path class="st0" d="M62.2,116.3L62.2,116.3c0,0.4-0.1,0.7-0.3,1c-0.2,0.2-0.5,0.4-0.9,0.4s-0.6-0.1-0.9-0.4
                c-0.2-0.2-0.3-0.6-0.3-1v-3.6h-1.1v3.6l0,0c0,0.3-0.1,0.5-0.2,0.7c-0.1,0.2-0.3,0.3-0.4,0.4c-0.2,0.1-0.4,0.1-0.6,0.1
                c-0.4,0-0.6-0.1-0.9-0.4c-0.2-0.2-0.3-0.6-0.3-1v-3.6h-1.1v3.9c0,0.6,0.2,1.1,0.5,1.5s0.8,0.6,1.5,0.6c0.4,0,0.7-0.1,1.1-0.2
                c0.3-0.2,0.5-0.4,0.7-0.7c0.1,0.3,0.4,0.5,0.6,0.7c0.3,0.2,0.6,0.3,1.1,0.3c0.4,0,0.8-0.1,1.1-0.3c0.2-0.1,0.4-0.3,0.6-0.5v0.7h1
                v-5.8h-1.1L62.2,116.3z"/>
                <path class="st0" d="M52.7,112.4c-0.4-0.3-0.9-0.5-1.4-0.6c-0.6-0.1-1.1,0-1.6,0.2s-0.8,0.5-1.2,0.9c-0.3,0.4-0.5,0.9-0.6,1.5
                s-0.1,1.1,0.1,1.6c0.2,0.5,0.4,0.9,0.8,1.2c0.4,0.3,0.9,0.5,1.4,0.6c0.6,0.1,1.1,0,1.5-0.1c0.5-0.2,0.8-0.5,1.2-0.9
                c0.3-0.4,0.5-0.9,0.6-1.5s0.1-1.1-0.1-1.6C53.4,113.1,53.1,112.7,52.7,112.4z M52.4,115.1c-0.1,0.4-0.2,0.7-0.3,1
                c-0.2,0.3-0.4,0.5-0.6,0.6c-0.3,0.1-0.6,0.2-1,0.1c-0.5-0.1-0.9-0.3-1.1-0.7c-0.2-0.4-0.3-0.9-0.2-1.5s0.3-1,0.6-1.4
                c0.3-0.3,0.8-0.5,1.3-0.4s0.9,0.3,1.1,0.7C52.5,114,52.5,114.5,52.4,115.1z"/>
                <path class="st0" d="M45.3,113.7c-0.1,0.2-0.1,0.4-0.2,0.6s-0.2,0.4-0.4,0.5c-0.1,0.1-0.3,0.2-0.5,0.3c-0.2,0.1-0.4,0.1-0.7,0.1
                c-0.2,0-0.4-0.1-0.6-0.2l-0.3,1c0.2,0.1,0.3,0.1,0.5,0.2s0.4,0,0.6,0c0.2,0,0.4,0,0.5-0.1c0.2-0.1,0.3-0.1,0.5-0.3
                c0.1-0.1,0.1-0.1,0.2-0.2l-0.2,0.9l1,0.3l1.5-5.6l-1.1-0.3L45.3,113.7z"/>
                <path class="st0" d="M40.7,109c-0.5-0.2-1.1-0.2-1.6-0.1s-1,0.4-1.4,0.8l0.9,0.7c0.2-0.2,0.5-0.4,0.8-0.4c0.3-0.1,0.6,0,0.9,0.1
                c0.5,0.2,0.8,0.5,1,0.9c0.1,0.4,0.1,0.8,0,1.2l-3.5-1.3l0,0l-0.5-0.2c-0.3,0.6-0.4,1.2-0.4,1.8c0,0.5,0.2,1,0.5,1.4
                s0.7,0.7,1.3,0.9c0.5,0.2,1,0.3,1.5,0.2s0.9-0.3,1.3-0.7c0.4-0.4,0.7-0.8,0.9-1.4s0.3-1.1,0.2-1.6s-0.3-1-0.6-1.4
                C41.7,109.5,41.2,109.2,40.7,109z M40.3,113.8c-0.4,0.2-0.9,0.3-1.4,0.1c-0.5-0.2-0.8-0.5-0.9-0.9c-0.1-0.3-0.1-0.7,0-1.1l3,1.1
                C40.8,113.3,40.6,113.6,40.3,113.8z"/>
                <path class="st0" d="M23.9,102.8c-0.1,0.2-0.3,0.3-0.5,0.5c-0.2,0.1-0.3,0.2-0.5,0.3c-0.2,0.1-0.4,0.1-0.6,0.1s-0.4-0.1-0.6-0.2
                s-0.3-0.3-0.5-0.4l-0.7,0.8c0.1,0.1,0.3,0.3,0.4,0.4c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0.1,0.4,0.1,0.5,0.1c0.2,0,0.4,0,0.6,0
                c0.1,0,0.2-0.1,0.3-0.1l-0.6,0.8L23,106l3.7-4.5l-0.9-0.7L23.9,102.8z"/>
                <path class="st0" d="M22.5,99.5c0.2-0.5,0.2-1,0-1.5c-0.1-0.5-0.4-0.9-0.8-1.4c-0.4-0.4-0.9-0.7-1.4-0.8c-0.5-0.1-1.1-0.1-1.6,0.1
                l0.5,1c0.3-0.1,0.6-0.1,0.9-0.1c0.3,0.1,0.6,0.2,0.8,0.5c0.4,0.4,0.5,0.8,0.5,1.2c0,0.4-0.2,0.7-0.5,1.1l-2.7-2.7l0,0l-0.4-0.4
                c-0.5,0.5-0.9,0.9-1.1,1.5c-0.2,0.5-0.3,1-0.2,1.5s0.4,0.9,0.8,1.4c0.4,0.4,0.8,0.7,1.3,0.8c0.5,0.1,1,0.1,1.5-0.1s1-0.5,1.4-0.9
                C22.1,100.4,22.3,100,22.5,99.5z M19.4,100.8c-0.5,0-0.9-0.1-1.3-0.5s-0.5-0.7-0.5-1.2c0-0.3,0.2-0.6,0.5-1l2.2,2.2
                C20,100.7,19.7,100.8,19.4,100.8z"/>
                <path class="st0" d="M17.2,95.6c0.2-0.1,0.5-0.2,0.7-0.4c0.2-0.2,0.4-0.4,0.5-0.7c0.1-0.3,0.1-0.6,0.1-0.9c-0.1-0.3-0.2-0.7-0.5-1
                c-0.3-0.4-0.7-0.7-1.1-0.9c-0.3-0.1-0.7-0.1-1-0.1l0.7-0.5L16,90.2l-2.8,2.2c-0.2,0.2-0.4,0.3-0.5,0.5c-0.2,0.1-0.3,0.3-0.4,0.5
                c-0.2,0.4-0.2,0.8-0.1,1.2c0.1,0.4,0.3,0.8,0.6,1.2c0.4,0.5,0.9,0.9,1.4,1s1,0.1,1.4-0.1l-0.4-1c-0.3,0.1-0.6,0.1-0.9,0
                c-0.3-0.1-0.5-0.3-0.7-0.5c-0.3-0.4-0.4-0.7-0.4-1s0.2-0.5,0.5-0.7c0.2,0.2,0.3,0.3,0.5,0.5c0.3,0.3,0.5,0.5,0.8,0.7
                c0.2,0.2,0.5,0.4,0.7,0.6c0.3,0.2,0.5,0.3,0.8,0.4C16.7,95.6,16.9,95.6,17.2,95.6z M16.4,94.4c-0.1,0-0.3-0.1-0.4-0.2
                c-0.2-0.1-0.3-0.2-0.5-0.4c-0.2-0.2-0.4-0.3-0.6-0.5c-0.1-0.1-0.3-0.3-0.4-0.4c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.1,0.4-0.2,0.5-0.3
                c0.2-0.1,0.4-0.1,0.6-0.1c0.2,0,0.5,0.1,0.7,0.2c0.2,0.1,0.4,0.3,0.6,0.5c0.1,0.2,0.2,0.4,0.3,0.5c0,0.2,0,0.3,0,0.5
                c-0.1,0.1-0.1,0.3-0.3,0.3c-0.1,0.1-0.2,0.1-0.4,0.2C16.7,94.5,16.6,94.5,16.4,94.4z"/>
                <path class="st0" d="M14,89.7c0.3-0.4,0.4-0.9,0.5-1.4c0-0.5-0.1-0.9-0.4-1.4c-0.3-0.5-0.7-0.8-1.1-1c-0.1-0.1-0.3-0.1-0.4-0.1
                l0.5-0.3l-0.5-0.9l-6.6,4l0.6,1l2-1.2c0,0.1-0.1,0.2-0.1,0.3c0,0.5,0.1,0.9,0.4,1.4c0.3,0.5,0.6,0.8,1.1,1c0.4,0.2,0.9,0.3,1.4,0.2
                c0.5-0.1,1-0.2,1.5-0.5C13.3,90.5,13.7,90.1,14,89.7z M12.2,89.7c-0.3,0.2-0.7,0.3-1,0.4s-0.6,0-0.9-0.1c-0.3,0-0.5-0.3-0.7-0.6
                c-0.2-0.3-0.3-0.6-0.2-0.9c0-0.3,0.1-0.6,0.4-0.8c0.2-0.3,0.5-0.5,0.8-0.7s0.7-0.3,1-0.4s0.6,0,0.9,0.1c0.3,0.1,0.5,0.3,0.7,0.6
                c0.2,0.3,0.3,0.6,0.2,0.9c0,0.3-0.2,0.6-0.4,0.8C12.8,89.3,12.5,89.5,12.2,89.7z"/>
                <path class="st0" d="M10.2,80l-3.4,1.3c-0.4,0.1-0.7,0.1-1,0S5.3,81,5.2,80.6C5,80.3,5,80,5.2,79.7C5.3,79.4,5.6,79.2,6,79l3.4-1.2
                L9,76.7L5.6,78c-0.3,0.1-0.5,0.1-0.7,0.1S4.5,78,4.3,77.8S4,77.5,3.9,77.3c-0.1-0.3-0.1-0.6,0-0.9s0.4-0.5,0.8-0.6l3.4-1.2
                l-0.4-1.1L4,74.8c-0.6,0.2-1,0.6-1.2,1c-0.2,0.5-0.2,1,0,1.6C3,77.7,3.2,78,3.5,78.3c0.3,0.2,0.6,0.4,0.9,0.4
                C4.2,78.9,4,79.2,4,79.5c-0.1,0.3,0,0.7,0.1,1.1s0.3,0.7,0.6,0.9c0.2,0.2,0.5,0.3,0.7,0.4l-0.6,0.2L5.1,83l5.5-2L10.2,80z"/>
                <path class="st0" d="M4.8,73c0.6-0.1,1.1-0.3,1.5-0.7C6.7,72,7,71.6,7.1,71.1c0.2-0.5,0.2-1,0.1-1.6s-0.3-1-0.7-1.4
                c-0.3-0.4-0.8-0.6-1.3-0.8c-0.5-0.1-1-0.1-1.6,0C3,67.5,2.5,67.7,2.1,68s-0.7,0.7-0.9,1.2s-0.2,1-0.1,1.6s0.3,1,0.7,1.4
                c0.3,0.4,0.8,0.6,1.3,0.8C3.6,73.1,4.2,73.1,4.8,73z M2.2,70.6c-0.1-0.5,0-1,0.3-1.3s0.8-0.6,1.4-0.7s1.1-0.1,1.5,0.1
                s0.7,0.6,0.8,1.1c0.1,0.5,0,1-0.3,1.3s-0.8,0.6-1.4,0.7c-0.4,0.1-0.7,0.1-1.1,0c-0.3-0.1-0.6-0.2-0.8-0.4
                C2.4,71.2,2.3,70.9,2.2,70.6z"/>
                <path class="st0" d="M6.3,64.3l-2.9,0.3c-0.2,0-0.4,0-0.7,0c-0.2,0-0.4-0.1-0.6-0.2S1.8,64.2,1.6,64c-0.1-0.2-0.2-0.4-0.3-0.6
                c0-0.2,0-0.4,0-0.6l-1.1,0.1c0,0.2,0,0.4,0,0.6c0,0.2,0.1,0.4,0.2,0.5c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.1,0.3,0.3,0.4,0.4
                c0.1,0,0.2,0.1,0.3,0.1l-1,0.1l0.1,1l5.8-0.6V64.3z"/>
                <path class="st0" d="M1.5,61.2c0.5,0.2,1,0.4,1.7,0.4c0.6,0,1.1-0.1,1.6-0.4c0.5-0.2,0.8-0.6,1.1-1c0.3-0.4,0.4-0.9,0.4-1.5
                s-0.1-1.1-0.4-1.5c-0.3-0.5-0.7-0.8-1.2-1l-0.4,1.1c0.3,0.1,0.5,0.3,0.7,0.6s0.2,0.6,0.2,0.9c0,0.5-0.2,0.9-0.5,1.2
                c-0.3,0.2-0.7,0.3-1.2,0.4v-3.8l0,0V56c-0.7,0-1.3,0-1.8,0.2s-0.9,0.5-1.2,0.9c-0.4,0.5-0.5,1-0.5,1.6c0,0.6,0.1,1.1,0.4,1.5
                C0.6,60.6,1,61,1.5,61.2z M1.5,57.5c0.2-0.2,0.6-0.3,1-0.4v3.2c-0.4-0.1-0.7-0.2-1-0.4C1.2,59.7,1,59.2,1,58.7S1.2,57.8,1.5,57.5z"
                />
                <path class="st0" d="M8.8,41.7l-2.8-1c-0.2-0.1-0.4-0.2-0.6-0.3S5.1,40.2,4.9,40c-0.1-0.2-0.2-0.3-0.3-0.6c-0.1-0.2-0.1-0.5,0-0.7
                s0.1-0.4,0.2-0.6l-1-0.3c-0.1,0.2-0.2,0.3-0.2,0.5s-0.1,0.4-0.1,0.6c0,0.2,0,0.4,0.1,0.5c0,0.2,0.1,0.3,0.2,0.5
                C3.9,40,3.9,40,4,40.1L3.3,40L3,41l5.5,1.9L8.8,41.7z"/>
                <path class="st0" d="M7.1,37.9c0.5,0.3,1.1,0.4,1.6,0.3c0.5,0,1-0.2,1.4-0.5s0.7-0.7,1-1.2c0.2-0.5,0.3-1,0.3-1.6
                c-0.1-0.5-0.3-1-0.7-1.4l-0.8,0.9c0.2,0.3,0.3,0.5,0.4,0.8c0,0.3,0,0.6-0.2,0.9c-0.2,0.5-0.6,0.8-1,0.9c-0.4,0.1-0.8,0-1.2-0.1
                l1.6-3.4l0,0L9.7,33c-0.6-0.3-1.2-0.5-1.8-0.5c-0.5,0-1,0.1-1.5,0.4c-0.4,0.3-0.8,0.7-1,1.2s-0.3,1-0.3,1.5c0.1,0.5,0.2,1,0.6,1.4
                C6.1,37.3,6.5,37.7,7.1,37.9z M6.4,34.4c0.2-0.5,0.5-0.7,0.9-0.8c0.3-0.1,0.7,0,1.1,0.1l-1.3,2.9c-0.3-0.2-0.6-0.5-0.7-0.8
                C6.1,35.4,6.1,34.9,6.4,34.4z"/>
                <path class="st0" d="M9.7,30.1c-0.2-0.3-0.3-0.5-0.3-0.8s0.1-0.6,0.3-0.8c0.3-0.4,0.5-0.7,0.8-0.7s0.5,0,0.9,0.2
                c-0.1,0.2-0.2,0.4-0.3,0.6c-0.1,0.3-0.3,0.7-0.4,1c-0.1,0.3-0.2,0.6-0.3,0.9s-0.1,0.6-0.1,0.8c0,0.3,0.1,0.5,0.2,0.7
                c0.1,0.2,0.3,0.4,0.6,0.5c0.3,0.2,0.6,0.3,0.9,0.3c0.3,0,0.6-0.1,0.9-0.2c0.3-0.2,0.5-0.4,0.8-0.8c0.3-0.5,0.4-0.9,0.4-1.3
                c0-0.3-0.1-0.7-0.2-1l0.8,0.5l0.5-0.9l-3.2-2c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.4-0.1-0.6-0.2c-0.4,0-0.8,0-1.2,0.3
                c-0.4,0.2-0.7,0.6-0.9,1c-0.4,0.6-0.5,1.1-0.5,1.6s0.2,0.9,0.6,1.3L9.7,30.1z M11.8,29c0.1-0.2,0.2-0.4,0.2-0.6
                c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0.1,0.3,0.3,0.4,0.4c0.1,0.1,0.2,0.3,0.3,0.5s0.1,0.4,0.1,0.7c0,0.3-0.1,0.5-0.3,0.8
                c-0.1,0.2-0.3,0.4-0.4,0.4c-0.1,0.1-0.3,0.1-0.4,0.1c-0.1,0-0.3,0-0.4-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.1-0.2-0.1-0.4
                c0-0.1,0-0.3,0-0.5s0.1-0.4,0.2-0.6C11.6,29.5,11.7,29.3,11.8,29z"/>
                <path class="st0" d="M12.7,22.1c-0.3,0.4-0.5,0.9-0.6,1.4c0,0.5,0.1,0.9,0.3,1.4s0.6,0.9,1,1.2c0.5,0.4,0.9,0.6,1.4,0.7
                s1,0.1,1.4,0c0.5-0.1,0.9-0.4,1.2-0.9c0.3-0.4,0.5-0.9,0.6-1.4c0-0.2,0-0.3,0-0.5l0.4,0.4l0.6-0.8l-6-4.7l-0.7,0.9l1.9,1.5
                c-0.1,0-0.2,0-0.3,0C13.4,21.4,13,21.7,12.7,22.1z M16.9,23.5c0.2,0.3,0.3,0.6,0.3,0.9s-0.1,0.6-0.4,0.9c-0.2,0.3-0.5,0.5-0.8,0.5
                c-0.3,0.1-0.6,0.1-0.9,0s-0.6-0.3-0.9-0.5c-0.3-0.2-0.5-0.5-0.7-0.8s-0.3-0.6-0.2-0.9c0-0.3,0.1-0.6,0.4-0.9
                c0.2-0.3,0.5-0.5,0.7-0.5c0.3-0.1,0.6-0.1,0.9,0.1c0.3,0.1,0.6,0.3,0.9,0.5l0,0C16.5,22.9,16.7,23.2,16.9,23.5z"/>
                <path class="st0" d="M24,11.7c-0.5-0.1-1,0.1-1.5,0.5c-0.3,0.3-0.5,0.5-0.6,0.9c-0.1,0.3-0.2,0.7-0.1,1c-0.3-0.1-0.6-0.2-0.9-0.1
                c-0.3,0.1-0.7,0.2-1,0.5s-0.5,0.6-0.6,0.9c-0.1,0.3-0.1,0.5-0.1,0.8l-0.4-0.5L18,16.3l3.8,4.4l0.9-0.7l-2.4-2.8l0,0
                c-0.3-0.3-0.4-0.6-0.4-0.9c0-0.3,0.1-0.6,0.4-0.8s0.6-0.3,0.9-0.3s0.6,0.2,0.9,0.6l2.3,2.8l0.9-0.7L22.9,15l0,0
                c-0.2-0.2-0.3-0.4-0.4-0.7c0-0.2,0-0.4,0-0.6c0.1-0.2,0.2-0.4,0.4-0.5c0.3-0.2,0.6-0.3,0.9-0.3s0.6,0.2,0.9,0.5l2.4,2.8l0.9-0.7
                l-2.5-3C25,12,24.5,11.7,24,11.7z"/>
                <path class="st0" d="M31,8.2c-0.4-0.3-0.9-0.4-1.4-0.4s-1,0.2-1.5,0.5C27.6,8.6,27.3,9,27,9.4c-0.2,0.4-0.3,0.9-0.3,1.4
                s0.2,1,0.5,1.6c0.3,0.5,0.7,0.9,1.1,1.2s0.9,0.4,1.4,0.4s1-0.2,1.5-0.5s0.9-0.7,1.1-1.1c0.2-0.4,0.3-0.9,0.3-1.4s-0.2-1-0.5-1.6
                C31.9,8.9,31.5,8.5,31,8.2z M30.8,12.6c-0.5,0.3-0.9,0.3-1.3,0.2c-0.4-0.2-0.8-0.5-1.1-1c-0.2-0.3-0.3-0.7-0.4-1s0-0.6,0.1-0.9
                c0.1-0.3,0.3-0.5,0.7-0.7C29.2,8.9,29.6,8.8,30,9s0.8,0.5,1.1,1c0.3,0.5,0.5,1,0.4,1.5S31.3,12.3,30.8,12.6z"/>
                <path class="st0" d="M34.4,7.5c0-0.2-0.1-0.4,0-0.6c0-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.3-0.4,0.5-0.5s0.4-0.2,0.6-0.2l-0.5-1
                c-0.2,0-0.3,0.1-0.5,0.2S34.4,5,34.2,5.1c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0.2-0.2,0.3-0.2,0.5c0,0.1,0,0.2,0,0.3l-0.4-0.9l-0.9,0.4
                L35,11l1-0.5l-1.3-2.6C34.6,7.9,34.5,7.7,34.4,7.5z"/>
                <path class="st0" d="M38.3,8.2c0.4,0.3,0.8,0.6,1.3,0.6c0.5,0.1,1,0,1.6-0.2c0.5-0.2,1-0.5,1.3-0.9S43,6.8,43,6.2l-1.2,0.1
                c0,0.3-0.1,0.6-0.3,0.9c-0.2,0.2-0.4,0.4-0.8,0.5c-0.5,0.2-0.9,0.2-1.3-0.1c-0.3-0.2-0.6-0.5-0.8-1l3.5-1.3l0,0l0.5-0.2
                c-0.2-0.7-0.5-1.2-0.9-1.6s-0.8-0.7-1.3-0.8s-1-0.1-1.6,0.1c-0.5,0.2-1,0.5-1.3,0.9S37,4.5,36.9,5.1c-0.1,0.5,0,1.1,0.2,1.7
                C37.6,7.4,37.9,7.9,38.3,8.2z M38.4,4.8c0.1-0.4,0.5-0.7,1-0.9c0.5-0.2,0.9-0.2,1.2,0.1c0.3,0.2,0.5,0.4,0.7,0.8l-3,1.1
                C38.3,5.5,38.3,5.1,38.4,4.8z"/>
            </g>
        </svg>

    )
}