import { createSlice } from '@reduxjs/toolkit'
import sanityClient from '../../sanityClient'

export const servicesSlice = createSlice({
    name: 'services',
    initialState: {
        value: []
    },
    reducers: {
        loadServices: (state, action) => {
            state.value = action.payload
        },
    }
})

export const fetchServices = (dispatch) => {
    sanityClient.fetch(`*[_type == "service"]{
    name,
    title,
    description,
    image{
        asset->{
            _id,
            url
        },
        alt
    }
}`)
        .then((data) => dispatch(loadServices(data)))
        .catch((err) => console.error(err));
}

// Action creators are generated for each case reducer function
export const { loadServices } = servicesSlice.actions

export default servicesSlice.reducer

