import React, { useEffect, useState } from 'react';
import sanityClient from '../../sanityClient';
import TeamMember from './TeamMember';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';

const TeamList = (props) => {

    const { t } = useTranslation();
    const teamMembers = useSelector((state) => state.teamMembers.value)

    const getView = () => {
        if (teamMembers.length > 0) {
            return <section className="flex flex-col justify-center items-center bg-greenEllie-dark pb-32">
                <p className="text-whiteEllie text-3xl md:text-4xl lg:text-5xl font-display px-20 lg:px-48 pt-10 pb-20 lg:py-32">
                    {t("meetTeam")}
                </p>
                <div className="grid md:grid-cols-3 grid-cols-2 gap-x-32 lg:gap-x-40 gap-y-8 lg:gap-y-32 px-20 lg:px-48">
                    {teamMembers.map((teamMember, i) => {
                        return <TeamMember key={i} teamMember={teamMember} />
                    })}
                </div>
            </section>
        } else {
            return <div></div>
        }
    }

    return (getView())
}
export default TeamList;