import React from 'react';
import NavigationListItem from './NavigationListItem';
import image from '../../images/image 6.png'
import { useTranslation } from "react-i18next";

const NavigationList = (props) => {
    const { t } = useTranslation();


    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const goToTop = () => {
        window.scrollTo(0, 0)
    }

    return (
        <section className={"flex flex-col px-10  " + props.bgStyle}>
            <div className="flex">
                <div className="w-full lg:w-4/6">
                    <ul className='h-full flex flex-col justify-between'>
                        <div onClick={goToTop} ><NavigationListItem name={t("services")} textStyle={props.textStyle} to="/services" /></div>
                        <div onClick={goToTop} ><NavigationListItem name={t("portfolio")} textStyle={props.textStyle} to="/portfolio" /></div>
                        <div onClick={goToTop} ><NavigationListItem name={t("about")} textStyle={props.textStyle} to="/about" /></div>
                        <div onClick={goToTop} ><NavigationListItem name={t("contact")} textStyle={props.textStyle} to="/contact" /></div>
                    </ul>
                </div>
                <div className="hidden lg:block w-2/6">
                    <div className="h-full p-10">
                        <img className="object-cover w-full" src={image} alt="Display" />
                    </div>
                </div>
            </div>
            <div className="flex text-greenEllie-dark w-full justify-center lg:justify-end px-10 pt-10 pb-20">
                <div className="hover:opacity-50 cursor-pointer" onClick={scrollToTop}>
                    <svg width="60" height="60" viewBox="0 0 63 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="33" cy="30" r="30" fill="#FAF9F5" />
                        <path d="M34.4424 46.5L34.4424 15" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M18.6924 30.75L34.4424 15L50.1924 30.75" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
            </div>
        </section>

    );
}
export default NavigationList;