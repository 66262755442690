import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from "react-router-dom";
import i18n from '../../i18n';
import { useTranslation } from "react-i18next";
import { motion, AnimatePresence } from 'framer-motion'
import Logo from '../../images/eliepaysage-logo.svg'
import LogoWhite from '../../images/eliepaysage-logo-white.svg'
import './Navbar.css';
import NavigationListItem from '../navigation/NavigationListItem';
import NavigationListItemMenu from '../navigation/NavigationListItemMenu';

const Navbar = ({ variants }) => {
    const [navbarOpen, setNavbarOpen] = React.useState(false);
    const [bgOpacity, setBgOpacity] = useState("")
    //Calling t and i18n method from useTranslation hook 
    const { t, i18n } = useTranslation();
    const location = useLocation();
    let navbarBackground = "";
    let navbarLink = "";
    let navbarLanguage = "";
    let navbarBorder = "";
    let iconColor = "";
    let mobileIconColors = "";

    useEffect(() => {
        if (bgOpacity === "bg-odd") {
            setBgOpacity("bg-even")
        } else {
            setBgOpacity("bg-odd")
        }
    }, [location])


    const whiteNavbar = () => {
        navbarBackground = "bg-whiteEllie";
        navbarLink = "navbar-link-green";
        navbarLanguage = "navbar-language-green";
        navbarBorder = "border-greenEllie-dark";
        iconColor = "text-greenEllie-dark";
        mobileIconColors = "text-whiteEllie bg-greenEllie-dark";
    }

    const greenNavbar = () => {
        navbarBackground = "bg-greenEllie-dark";
        navbarLink = "navbar-link-white";
        navbarLanguage = "navbar-language-white";
        navbarBorder = "border-whiteEllie";
        iconColor = "text-whiteEllie";
        mobileIconColors = "text-greenEllie-dark bg-whiteEllie";

    }

    if (location.pathname.includes('/services')) {
        whiteNavbar();
    } else if (location.pathname.includes('/portfolio')) {
        greenNavbar();
    } else if (location.pathname.includes('/about')) {
        greenNavbar();
    } else if (location.pathname.includes('/contact')) {
        whiteNavbar();
    } else {
        whiteNavbar();
    }

    const changeLanguageHandler = (language) => {
        i18n.changeLanguage(language);
    }

    const getLogo = () => {
        if (iconColor === 'text-whiteEllie') {
            return <img className='logo' src={LogoWhite} alt="Elie Paysage logo" />
        } else {
            return <img className='logo' src={Logo} alt="Elie Paysage logo" />
        }
    }

    const mobileMenuVariants = {
        hidden: {
            y: "-100vh",
        },
        visible: {
            y: "0vh",
            transition: { delay: 0, duration: 0.5 },
        },
        exit: {
            y: "-100vh",
            transition: { delay: 0, duration: 0.5 },
        }
    }

    const getMenuMobile = () => {
        if (navbarOpen) {
            return <motion.div className={"lg:hidden fixed  z-40 h-max w-full bg-greenEllie-dark flex flex-col"} variants={mobileMenuVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                key={"mobileMenu" + navbarOpen}>
                <div className="mobile-menu-fadein opacity-0">
                    <div className="topo absolute w-full h-full top-0 z-10"></div>
                    <div className="z-20 relative h-max pt-4 pb-10 px-10 flex flex-col justify-between">
                        <button className="text-whiteEllie -ml-3 hover:opacity-20" onClick={() => setNavbarOpen(!navbarOpen)}>
                            <svg className="h-14 w-14" viewBox="0 0 121 121" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="33.4144" y="83.2075" width="70.5833" height="6.05" transform="rotate(-45 33.4144 83.2075)" fill="currentColor" />
                                <rect x="36.278" y="33" width="70.5833" height="6.05" transform="rotate(45 36.278 33)" fill="currentColor" />
                            </svg>

                        </button>
                        <ul className='flex flex-col justify-between'>
                            <div className="flex flex-col">
                                <div onClick={() => { window.scrollTo(0, 0); setNavbarOpen(!navbarOpen) }} ><NavigationListItemMenu name={t("home")} textStyle="text-whiteEllie" to="/" /></div>
                                <div onClick={() => { window.scrollTo(0, 0); setNavbarOpen(!navbarOpen) }} ><NavigationListItemMenu name={t("services")} textStyle="text-whiteEllie" to="/services" /></div>
                                <div onClick={() => { window.scrollTo(0, 0); setNavbarOpen(!navbarOpen) }} ><NavigationListItemMenu name={t("portfolio")} textStyle="text-whiteEllie" to="/portfolio" /></div>
                                <div onClick={() => { window.scrollTo(0, 0); setNavbarOpen(!navbarOpen) }} ><NavigationListItemMenu name={t("about")} textStyle="text-whiteEllie" to="/about" /></div>
                                <div onClick={() => { window.scrollTo(0, 0); setNavbarOpen(!navbarOpen) }} ><NavigationListItemMenu name={t("contact")} textStyle="text-whiteEllie" to="/contact" /></div>
                            </div>
                        </ul>
                        <div className="flex flex-row text-whiteEllie space-x-14  w-full justify-center">
                            <a href="https://www.facebook.com/Elie-Paysage-104619888077457/" target="_blank" rel="noreferrer noreferrer" className="inline-flex items-center justify-center rounded-full h-16 w-16 md:h-20 md:w-20 bg-greenEllie-reallyDark hover:bg-whiteEllie hover:text-greenEllie-reallyDark cursor-pointer">
                                <svg className="h-8 w-8 md:h-12 md:w-12" viewBox="0 0 19 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18 1H13.5C11.5109 1 9.60322 1.79018 8.1967 3.1967C6.79018 4.60322 6 6.51088 6 8.5V13H1.5V19H6V31H12V19H16.5L18 13H12V8.5C12 8.10218 12.158 7.72064 12.4393 7.43934C12.7206 7.15804 13.1022 7 13.5 7H18V1Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </a>
                            <a href="https://www.instagram.com/eliepaysage/" target="_blank" rel="noreferrer noreferrer" className="inline-flex items-center justify-center rounded-full h-16 w-16 md:h-20 md:w-20 bg-greenEllie-reallyDark hover:bg-whiteEllie hover:text-greenEllie-reallyDark cursor-pointer">
                                <svg className="h-8 w-8 md:h-12 md:w-12" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M23.5 1H8.5C4.35786 1 1 4.35786 1 8.5V23.5C1 27.6421 4.35786 31 8.5 31H23.5C27.6421 31 31 27.6421 31 23.5V8.5C31 4.35786 27.6421 1 23.5 1Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M21.9999 15.055C22.185 16.3034 21.9718 17.5783 21.3906 18.6985C20.8093 19.8187 19.8896 20.7271 18.7624 21.2945C17.6351 21.8619 16.3576 22.0594 15.1116 21.8589C13.8656 21.6584 12.7146 21.0701 11.8222 20.1777C10.9298 19.2854 10.3415 18.1343 10.141 16.8883C9.94053 15.6423 10.138 14.3648 10.7054 13.2376C11.2728 12.1103 12.1812 11.1906 13.3014 10.6094C14.4216 10.0281 15.6966 9.81488 16.9449 9.99999C18.2183 10.1888 19.3972 10.7822 20.3075 11.6925C21.2177 12.6027 21.8111 13.7816 21.9999 15.055Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M24.25 7.75H24.265" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </a>
                        </div>
                        <div className="flex w-full justify-center space-x-3">
                            <button className={"text-whiteEllie text-3xl md:text-4xl hover:opacity-20 " + (i18n.language === "fr" ? "font-bold" : "")} onClick={() => changeLanguageHandler("fr")}>FR</button>
                            <div className="uppercase text-whiteEllie text-3xl md:text-4xl">|</div>
                            <button className={"text-whiteEllie text-3xl md:text-4xl hover:opacity-20 " + (i18n.language === "en" ? "font-bold" : "")} onClick={() => changeLanguageHandler("en")}>EN</button>
                        </div>
                    </div>
                </div>
            </motion.div>
        } else {
            return <div key={"mobileMenu" + navbarOpen}></div>;
        }

    }

    return (
        <div>
            <AnimatePresence>
                {getMenuMobile()}
            </AnimatePresence>
            <motion.div className={navbarBackground}
                variants={variants}
                initial="hidden"
                animate="visible"
                exit="exit">
                <div className={"flex flex-wrap items-center justify-between pt-4 pb-1 mx-10 lg:mx-20 lg:border-b-05 " + navbarBorder}>
                    <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
                        <a
                            className={"hidden lg:inline-block font-bold leading-relaxed mr-4 py-2 whitespace-nowrap uppercase " + iconColor}
                            href="/"
                        >
                            {getLogo()}
                        </a>
                        <button
                            className={"rounded-full -ml-3 inline-block lg:hidden " + mobileIconColors}
                            type="button"
                            onClick={() => setNavbarOpen(!navbarOpen)}
                        >
                            <svg className="h-14 w-14" viewBox="0 0 121 121" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="26.2166" y="44.3667" width="70.5833" height="6.05" fill="currentColor" />
                                <rect x="26.2166" y="70.5835" width="70.5833" height="6.05" fill="currentColor" />
                            </svg>

                        </button>
                    </div>
                    <div className="lg:flex flex-grow items-center hidden">
                        <ul className="flex flex-row list-none ml-auto">
                            <li>
                                <NavLink to="/" className={navbarLink}>{t("home")}</NavLink>
                            </li>
                            <li>
                                <NavLink to="/services" className={navbarLink}>{t("services")}</NavLink>
                            </li>
                            <li>
                                <NavLink to="/portfolio" className={navbarLink}>{t("portfolio")}</NavLink>
                            </li>
                            <li>
                                <NavLink to="/about" className={navbarLink}>{t("about")}</NavLink>
                            </li>
                            <li>
                                <NavLink to="/contact" className={navbarLink}>{t("contact")}</NavLink>
                            </li>
                        </ul>
                        <div className="flex ml-10">
                            <button className={navbarLanguage + " " + (i18n.language === "fr" ? "font-bold" : "")} onClick={() => changeLanguageHandler("fr")}>FR</button>
                            <div className={"uppercase " + iconColor}>|</div>
                            <button className={navbarLanguage + " " + (i18n.language === "en" ? "font-bold" : "")} onClick={() => changeLanguageHandler("en")}>EN</button>
                        </div>
                    </div>
                </div>
            </motion.div>
        </div>
    );
}
export default Navbar;