import React from 'react';
import ContactForm from '../components/contact/ContactForm';
import Footer from '../components/Footer';
import {motion} from 'framer-motion'

const Contact = ({variants}) => {
  return (
    <motion.div
      variants={variants}
      initial="hidden"
      animate="visible"
      exit="exit">
      <ContactForm />
      <Footer />
    </motion.div>
  );
}
export default Contact;