import React from 'react';
import TeamList from '../components/about/TeamList';
import Footer from '../components/Footer';
import NavigationList from '../components/navigation/NavigationList';
import {motion} from 'framer-motion'

const About = ({ variants }) => {
  return (
    <motion.div className="flex-grow text-xs md:text-sm lg:text-base"
      variants={variants}
      initial="hidden"
      animate="visible"
      exit="exit">
      <TeamList />
      <NavigationList textStyle="text-whiteEllie border-whiteEllie" bgStyle="bg-greenEllie-dark" />
      <Footer />
    </motion.div>
  );
}
export default About;