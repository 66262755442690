import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion';

const serviceVariants = {
    hidden: {
        opacity: 0,
        x: "-1vw"
    },
    visible: {
        opacity: 1,
        x: "0vw",
        transition: { delay: 0, duration: 1 },
    },
    exit: {
        x: "-10vw",
        transition: {ease: "easeInOut"}
    }
}

const imgVariants = {
    hidden: {
        opacity: 0,
        x: "1vw"
    },
    visible: {
        opacity: 1,
        x: "0vw",
        transition: { delay: 0, duration: 1 },
    },
    exit: {
        x: "10vw",
        transition: {ease: "easeInOut"}
    }
}


const ServiceGallery = (props) => {
    const [current, setCurrent] = useState(0)
    const { t, i18n } = useTranslation();
    const services = useSelector((state) => state.services.value)


    const nextService = () => {
        if (current === (services.length - 1)) {
            setCurrent(0)
        } else {
            setCurrent(current + 1)
        }
    }

    const prevService = () => {
        if (current === 0) {
            setCurrent(services.length - 1)
        } else {
            setCurrent(current - 1)
        }
    }

    const getBarPercentage = () => {
        return 100 / services.length + "%";
    }

    const getView = () => {
        if (services.length > 0) {
            return <section className="bg-whiteEllie lg:h-screen flex flex-col">
                <div className="flex flex-col-reverse lg:flex-row lg:h-3/4">
                    <div className="flex flex-col justify-between w-full lg:w-1/2 px-10 lg:px-20 pt-10 lg:pt-20">
                        <motion.div key={current}
                            variants={serviceVariants}
                            initial="hidden"
                            animate="visible"
                            exit="exit">
                            <h1 className="text-greenEllie-dark mb-10 text-3xl md:text-4xl lg:text-5xl font-display font-bold">
                                {services[current].title[i18n.language]}
                            </h1>
                            <h2 className="text-greenEllie-dark font-bold font-body font-xl uppercase mb-2">{services[current].name[i18n.language]}</h2>
                            <p className="text-greenEllie-dark font-body font-xl">{services[current].description[i18n.language]}
                            </p>
                        </motion.div>
                        <Link to="/contact" className="flex lg:hidden py-10 justify-end">
                            <div className="flex text-greenEllie-dark uppercase font-bold font-body space-x-5">
                                <div>{t("serviceIWant")}</div>
                                <svg viewBox="0 0 35 34" className="h-4 w-4 md:h-5 md:w-5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2 16.8317L33.5 16.8317" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M17.75 2L33.5 16.8317L17.75 31.6633" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </div>
                        </Link>
                        <div>
                            <div className="flex justify-between md:space-x-32">
                                <div className="text-greenEllie-dark font-bold font-body uppercase">Service</div>
                                <div className="flex">
                                    <div className="text-greenEllie-dark font-bold font-body uppercase">{current + 1} /&nbsp;</div><div className="text-greenEllie-dark font-bold font-body uppercase opacity-20">{services.length}</div>
                                </div>
                            </div>
                            <div className="relative pt-1">
                                <div className="overflow-hidden h-1 mb-4 flex rounded bg-gray-200">
                                    {services.map((service, i) => {
                                        if (i <= current) {
                                            return <div key={i} style={{ width: getBarPercentage() }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-greenEllie-dark"></div>
                                        } else {
                                            return <div key={i} style={{ width: getBarPercentage() }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"></div>
                                        }
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <motion.div key={current} className="pt-10 lg:p-6 w-full lg:w-1/2"
                        variants={imgVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit">
                        <div className="px-10 lg:px-20 h-15 lg:h-full">
                            <img className="object-cover h-full w-full" src={services[current].image.asset.url} alt="Display" />
                        </div>
                    </motion.div>
                </div>
                <div className="flex flex-row justify-between w-full h-full pt-8 px-10 lg:px-20 items-center">
                    <div className="flex w-full lg:w-auto justify-between lg:justify-start lg:space-x-5">
                        <button onClick={prevService} className="rounded-full h-20 w-20 md:h-24 md:w-24 p-3 flex items-center justify-center border-2 border-greenEllie-dark text-greenEllie-dark hover:bg-greenEllie-dark hover:text-whiteEllie opacity-20 hover:opacity-100">
                            <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M87 44L1 44" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M44 87L1 44L44 1" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                        <button onClick={nextService} className="rounded-full h-20 w-20 md:h-24 md:w-24 p-3 flex items-center justify-center border-2 border-greenEllie-dark text-greenEllie-dark hover:bg-greenEllie-dark hover:text-whiteEllie opacity-20 hover:opacity-100">
                            <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 44H87" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M44 1L87 44L44 87" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                    </div>
                    <Link to="/contact" className="hidden lg:block">
                        <div className="flex text-greenEllie-dark uppercase font-bold font-body space-x-5">
                            <div>{t("serviceIWant")}</div>
                            <svg viewBox="0 0 35 34" className="h-6 w-6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2 16.8317L33.5 16.8317" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M17.75 2L33.5 16.8317L17.75 31.6633" stroke="currentColor" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </Link>
                </div>
            </section>
        } else {
            return <div></div>
        }
    }

    return (getView())
}
export default ServiceGallery;