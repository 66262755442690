import MowerSVG from '../images/mower.js'
import TreeSVG from '../images/trees.js'
import Dots from '../images/dots.js'

function Loading() {
    return (
        <div className="w-full h-max fixed bg-greenEllie-dark flex justify-center items-center relative">
            <div className='w-8/12 overflow-hidden md:w-auto'>
                <div className="container-loading w-full">
                    <div className="landscape"></div>
                </div>
                <div className="flex-row flex absolute top-48 items-baseline w-full">
                    <Dots />
                    <MowerSVG width={58} height={37}/>
                </div>
                <div className='-mt-20 absolute left-0 right-0'>
                    <h1 className='text-3xl text-white text-center font-display'>Bienvenue sur le site de <i>Elie paysage</i>...</h1>
                </div>
            </div>
        </div>
    )
}

export default Loading