import React, { useRef } from 'react';
import Footer from '../components/Footer';
import Landing from '../components/home/Landing';
import PortfolioList from '../components/portfolio/PortfolioList';
import NavigationList from '../components/navigation/NavigationList';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { motion } from "framer-motion"

const Home = ({ variants }) => {
  const { t } = useTranslation();
  const portfolioRef = useRef()

  function handleSeeMoreClick() {
    portfolioRef.current.scrollIntoView({ behavior: 'smooth' })
  }


  return (
    <motion.main className="flex-grow text-xs md:text-sm lg:text-base"
      variants={variants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <Landing seeMoreClick={handleSeeMoreClick} />
      <div className="bg-greenEllie-dark relative" ref={portfolioRef}>
        <div className="topo absolute w-full h-full top-0 z-10"></div>
        <div className="z-20 relative">
          <PortfolioList />
          <div className="-mt-20 mb-32 w-full flex justify-center">
            <Link to="/portfolio">
              <button onClick={() => window.scrollTo(0, 0)} className="font-body text-whiteEllie font-bold bg-transparent hover:bg-whiteEllie border border-whiteEllie hover:text-greenEllie-dark  py-2 px-8 flex space-x-10 items-center">
                <span>{t("visitPortfolio")}</span>
                <svg className="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 23L23 1" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M1 1H23V23" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </button>
            </Link>
          </div>
          <NavigationList textStyle="text-whiteEllie border-whiteEllie" bgStyle="bg-transparent" />
        </div>
      </div>
      <Footer />
    </motion.main>
  );
}
export default Home;