import React from 'react'
import { Routes, Route, useLocation } from "react-router-dom";
import About from '../pages/About';
import Home from '../pages/Home';
import Services from '../pages/Services';
import Portfolio from '../pages/Portfolio';
import Contact from '../pages/Contact';
import PortfolioItemPage from '../pages/PortfolioItemPage';
import { motion, AnimatePresence } from "framer-motion"
import Navbar from './navbar/Navbar';
import Newsletter from '../pages/Newsletter';

const containerVariants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: { delay: 0, duration: 1.5 },
    },
    exit: {
        opacity: 0,
        transition: { ease: 'easeInOut' }
    }
}

const RoutesNav = () => {
    const location = useLocation();

    return (
        <AnimatePresence>
            <Navbar variants={containerVariants} key={location.pathname} />
            <Routes location={location} key={location.key}>
                <Route path='/' exact element={<Home variants={containerVariants} />} />
                <Route path='/services' element={<Services variants={containerVariants} />} />
                <Route path='/portfolio' element={<Portfolio variants={containerVariants} />} />
                <Route path='/about' element={<About variants={containerVariants} />} />
                <Route path='/contact' element={<Contact variants={containerVariants} />} />
                <Route path='/portfolio/:id' element={<PortfolioItemPage variants={containerVariants} />} />
                <Route path="/newsletter" element={<Newsletter variants={containerVariants} />} />
            </Routes>
        </AnimatePresence>
    )
}

export default RoutesNav