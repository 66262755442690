import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Carousel from './Carousel';
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { motion } from 'framer-motion'

const textVariants = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        x: "1vw",
        transition: { delay: 0.5, duration: 1.5 },
    },
}

const Landing = (props) => {
    const { t } = useTranslation();
    const [cookies, setCookie] = useCookies(["privacy_policy_accept"]);
    const [hidePrivacyPolicyPopup, setHidePrivacyPolicyPopup] = useState(false)

    const acceptPrivacyPolicy = (value) => {
        if(value)
            setCookie("privacy_policy_accept", value, {
                path: "/"
            });

        setHidePrivacyPolicyPopup(true);
    }

    return (
        <section className="flex flex-col lg:h-screen bg-whiteEllie justify-between">
            <div class={"hidden z-50 top-1/3 left-0 right-0 w-6/12 lg:w-1/3 h-1/5 bg-greenEllie-dark shadow-xl pl-10 lg:pl-20 pr-5 lg:pr-10 py-4 lg:py-8 " + ((((cookies.privacy_policy_accept && cookies.privacy_policy_accept) === "true" ) || hidePrivacyPolicyPopup) ? "hidden" : "block")}>
                <div className="flex flex-col h-full text-whiteEllie justify-between">
                    <p>{t("policy")}</p>
                    <div className="flex flex-row justify-between">
                        <button onClick={() => acceptPrivacyPolicy(false)} className="text-whiteEllie">{t("decline")}</button>
                        <button onClick={() => acceptPrivacyPolicy(true)} className="font-bold text-whiteEllie">{t("accept")}</button>
                    </div>
                </div>
            </div>
            <div className="flex flex-col lg:flex-row items-center h-full">
                <div className="w-full lg:w-1/2 px-16 lg:px-20 pt-10 py-0 lg:py-10">
                    <motion.p className="text-greenEllie-dark text-2xl md:text-3xl lg:text-4xl font-display"
                        variants={textVariants}
                        initial="hidden"
                        animate="visible">
                        {t("landingPresentation")}
                    </motion.p>
                    <div className="flex flex-col lg:space-x-10 space-y-10 lg:space-y-0 lg:flex-row justify-between text-center py-10 md:py-24">
                        <Link to="/contact" >
                            <button onClick={() => window.scrollTo(0, 0)} className="flex w-full justify-between md:space-x-20 items-center justify-center font-bold bg-greenEllie-dark hover:bg-whiteEllie text-whiteEllie hover:text-greenEllie-dark border-greenEllie-dark border p-4 ">
                                <span className='text-sm whitespace-nowrap'>{t("requestQuote")}</span>
                                <svg className="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 23L23 1" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M1 1H23V23" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                            </button>
                        </Link>

                        <div>
                            <button onClick={props.seeMoreClick} className="flex w-full justify-between md:space-x-20 flex-row items-center font-bold bg-transparent hover:bg-greenEllie-dark text-greenEllie-dark hover:text-white p-4 border border-greenEllie-dark">
                                <span className='text-sm whitespace-nowrap'>{t("seeMore")}</span>
                                <svg className="h-6 w-6" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.5563 1.44365V32.5563" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M32.1127 17L16.5563 32.5563L0.999972 17" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                            </button>
                        </div>
                    </div>
                </div>
                <div className="w-full h-full lg:w-1/2 lg:p-6 flex justify-center flex-col">
                    <Carousel></Carousel>
                </div>
            </div>
            <div className="text-greenEllie-dark bg-whiteEllie -mt-24 px-20 space-x-5 pb-8 hidden lg:block">
                <a href="https://www.instagram.com/eliepaysage/" target="_blank" rel="noreferrer noreferrer" className="hover:opacity-20">
                    <svg className="h-6 w-6 inline-block" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M23.5 1H8.5C4.35786 1 1 4.35786 1 8.5V23.5C1 27.6421 4.35786 31 8.5 31H23.5C27.6421 31 31 27.6421 31 23.5V8.5C31 4.35786 27.6421 1 23.5 1Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M21.9999 15.055C22.185 16.3034 21.9718 17.5783 21.3906 18.6985C20.8093 19.8187 19.8896 20.7271 18.7624 21.2945C17.6351 21.8619 16.3576 22.0594 15.1116 21.8589C13.8656 21.6584 12.7146 21.0701 11.8222 20.1777C10.9298 19.2854 10.3415 18.1343 10.141 16.8883C9.94053 15.6423 10.138 14.3648 10.7054 13.2376C11.2728 12.1103 12.1812 11.1906 13.3014 10.6094C14.4216 10.0281 15.6966 9.81488 16.9449 9.99999C18.2183 10.1888 19.3972 10.7822 20.3075 11.6925C21.2177 12.6027 21.8111 13.7816 21.9999 15.055Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M24.25 7.75H24.265" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </a>
                <a href="https://www.facebook.com/Elie-Paysage-104619888077457/" target="_blank" rel="noreferrer noreferrer" className="hover:opacity-20">
                    <svg className="h-6 w-6 inline-block" viewBox="0 0 19 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 1H13.5C11.5109 1 9.60322 1.79018 8.1967 3.1967C6.79018 4.60322 6 6.51088 6 8.5V13H1.5V19H6V31H12V19H16.5L18 13H12V8.5C12 8.10218 12.158 7.72064 12.4393 7.43934C12.7206 7.15804 13.1022 7 13.5 7H18V1Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </a>
            </div>
        </section>
    );
}
export default Landing;