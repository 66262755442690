import React from 'react';
import { Link } from 'react-router-dom';
const NavigationListItem = (props) => {
    return (
        <Link to={props.to}>
            <li className={"flex justify-between items-center pr-1 uppercase text-3xl md:text-4xl lg:text-6xl font-display font-bold py-4 opacity-20 hover:opacity-100 border-b-2 " + props.textStyle}>
                {props.name}
                <svg className="h-6 w-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 23L23 1" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M1 1H23V23" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </li>
        </Link>
    );
}
export default NavigationListItem;