import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';


// Importing translation files

import translationFR from "./locales/fr/translation.json";
import translationEN from "./locales/en/translation.json";


//Creating object with the variables of imported translation files
const resources = {
  fr: {
    translation: translationFR,
  },
  en: {
    translation: translationEN,
  },
};

window.userLang = navigator.language || navigator.userLanguage;


//i18N Initialization

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: "fr",
    detection: {
        order: ["localStorage", "cookie"],
        caches: ["localStorage", "cookie"], // cache user language on
      },
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;