import React, { useState } from "react";
import { useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";

function Carousel() {
    const [current, setCurrent] = useState(0)
    const images = useSelector((state) => state.carousel.value)
    const { i18n } = useTranslation();


    const nextSlide = () => {
        if (current === (images.length - 1)) {
            setCurrent(0)
        } else {
            setCurrent(current + 1)
        }
    }

    const getImg = (image, i) => {
        if (current === i) {
            return <img className="absolute carousel z-20" src={image} />
        } else if (i === (current + 1)) {
            return <img className="absolute z-10" src={image} />
        } else if (current === images.length - 1 && i === 0) {
            return <img className="absolute z-10" src={image} />
        } else {
            return <img className="absolute" src={image} />
        }
    }
    //h-15 md:h-96 xl:h-600 py-0 lg:py-20 px-16 lg:px-20

    const getView = () => {
        if (images.length > 0) {
            return <div className="px-16 h-full items-start justify-center flex flex-col">
                <div className="overflow-hidden rounded-t-full w-full h-15 md:h-96 lg:h-4/6 relative">
                    {
                        images.map((item, i) => getImg(item.image.asset.url, i))
                    }
                </div>
                <div className="border border-greenEllie-dark rounded-l my-2 progress" onAnimationIteration={nextSlide} onAnim />
                <p className="text-greenEllie-dark mb-8 text-l font-body text-left">
                    {images[current].description[i18n.language]}
                </p>
            </div>
        } else {
            return <div></div>
        }
    }

    return (getView())
}

export default Carousel;

