import { createSlice } from '@reduxjs/toolkit'
import sanityClient from '../../sanityClient'

export const portfolioSlice = createSlice({
    name: 'portfolio',
    initialState: {
        value: [],
        currentIndex: 0
    },
    reducers: {
        loadPortfolioItems: (state, action) => {
            state.value = action.payload
        },
        updateCurrentIndex: (state, action) => {
            console.log('slice ex')
            console.log(action.payload)
            state.currentIndex = action.payload
        }
    }
})

export const fetchPortfolioItems = (dispatch) => {
    sanityClient.fetch(`*[_type == "portfolioItem"]{
        _id,
        title,
        subtitle,
        description,
        content,
        mainImage{
            asset->{
                _id,
                url
            },
            alt
        },
        images[]{
            asset->{
                _id,
                url
            },
            alt
        }
    }`)
        .then((data) => dispatch(loadPortfolioItems(data)))
        .catch((err) => console.error(err));
}


// Action creators are generated for each case reducer function
export const { loadPortfolioItems, updateCurrentIndex } = portfolioSlice.actions

export default portfolioSlice.reducer

