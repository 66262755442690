import { createSlice } from '@reduxjs/toolkit'
import sanityClient from '../../sanityClient'

export const teamSlice = createSlice({
    name: 'team',
    initialState: {
        value: []
    },
    reducers: {
        loadTeamMembers: (state, action) => {
            state.value = action.payload
        },
    }
})

export const fetchTeamMembers = (dispatch) => {
    sanityClient.fetch(`*[_type == "teamMember"]{
        firstName,
        lastName,
        position,
        image{
            asset->{
                _id,
                url
            },
            alt
        }
    }`)
        .then((data) => dispatch(loadTeamMembers(data)))
        .catch((err) => console.error(err));
}

// Action creators are generated for each case reducer function
export const { loadTeamMembers } = teamSlice.actions

export default teamSlice.reducer

