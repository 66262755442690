import { configureStore } from '@reduxjs/toolkit'
import carouselSlice from './reducers/carouselSlice'
import portfolioSlice from './reducers/portfolioSlice'
import servicesSlice from './reducers/servicesSlice'
import teamSlice from './reducers/teamSlice'
export default configureStore({
    reducer: {
        carousel: carouselSlice,
        services: servicesSlice,
        portfolioItems: portfolioSlice,
        teamMembers: teamSlice
    },
})